<template>
    <div class="element SelectField" :data-required="this.required" :class="error.error ? 'error' : ''">
        <label :for="`field_${this.id}`">{{ this.label }}</label>

        <select
            class="input"
            :id="`field_${this.id}`"
            :name="this.name"
            :readonly="!this.editing || this.readonly"
            :autocomplete="this.autocomplete"
            >
            <template v-for="(label, id) in resolveOptions()">
                <option :value="id" :selected="this.value == id">{{ label }}</option>
            </template>
        </select>
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<script>
import {ref, watch} from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

export default {
    props: mergeDeep(
        propDefaults,
        {
            show_none_option: {type: Boolean, required: false, default:false}
        }
    ),
    setup(props) {
        let input = ref("");
        const {error} = useFormValidation();
        // const validateInput = () => {
        //     if (props.required) {
        //         // validateTextField(props.label, input.value);
        //     }
        // };

        const resolveOptions = () => {
            let options;
            if (typeof props.options_json === "string") {
                try {
                    options = JSON.parse(props.options_json || "[]") || {};
                } catch (e) {
                    console.error("Error parsing options_json", props.name, props.id, props.options_json, e);
                    options = {}
                }
            } else {
                options = Object.values(props.options_json || {});
            }
            if (!props.value || !props.required) {
                options = {
                    "": trans("- Maak uw keuze -"),
                    ...options
                };
            }
            return options;
        };

        watch(
            () => props.value,
            () => {
                input.value = props.value;
            }, { immediate: true }
        );

        return {
            trans,
            resolveOptions,
            input,
            error
            // , validateInput
        };
    },
}
</script>
