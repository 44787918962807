<template>
    <div class="element EditorField" :data-required="this.required" :class="error.error ? 'error' : ''">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <div v-if="!this.editing">
            <div class="Content" v-html="input" />
        </div>
        <div :class="{ hidden: !this.editing }">
            <textarea
                class="input"
                ref="el"
                :id="`field_${this.id}`"
                :name="this.name"
                :readonly="!this.editing || this.readonly"
                :placeholder="this.placeholder"
                :autocomplete="this.autocomplete"
                :maxlength="this.maxlength"
                v-model="input"
                @keyup="validateInput"
                @blur="validateInput"
            />
        </div>
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import ClassicEditor from "ckeditor/simple/build/ckeditor";
import trans from "helpers/_translation";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    setup(props) {
        const el = ref();

        let input = ref("");
        const {validateTextField, error} = useFormValidation();
        const validateInput = () => {
            if (props.required) {
                validateTextField(props.label, input.value);
            }
        };

        let tmpWatcher;
        onMounted(async () => {
            input.value = props.value;
            tmpWatcher = watch(
                () => props.editing,
                (allow_editing) => {
                    if (!allow_editing) return;

                    if (!el.value.editor) {
                        ClassicEditor
                            .create(el.value, {
                                htmlSupport: {
                                    allow: [
                                        {
                                            name: /.*/,
                                            attributes: true,
                                            classes: true,
                                            styles: true
                                        }
                                    ],
                                    disallow: []
                                },
                                toolbar: {
                                    items: [
                                        'heading',
                                        '|',
                                        'bold',
                                        'italic',
                                        '|',
                                        'bulletedList',
                                        'numberedList',
                                        '|',
                                        'insertTable',
                                        '|',
                                        'undo',
                                        'redo'
                                    ]
                                },
                                // image: {
                                //     // Configure the available styles.
                                //     styles: [
                                //         'alignLeft', 'alignCenter', 'alignRight'
                                //     ],
                                //
                                //     // Configure the available image resize options.
                                //     resizeOptions: [
                                //         {
                                //             name: 'imageResize:original',
                                //             label: 'Original',
                                //             value: null
                                //         },
                                //         {
                                //             name: 'imageResize:50',
                                //             label: '50%',
                                //             value: '50'
                                //         },
                                //         {
                                //             name: 'imageResize:75',
                                //             label: '75%',
                                //             value: '75'
                                //         }
                                //     ],
                                //     toolbar: [
                                //         'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
                                //         '|',
                                //         'imageResize',
                                //         '|',
                                //         'imageTextAlternative'
                                //     ]
                                // },
                                link: {
                                    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                    addTargetToExternalLinks: true,

                                    // Let the users control the "download" attribute of each link.
                                    decorators: [
                                        {
                                            mode: 'manual',
                                            label: 'Openen in nieuw venster',
                                            attributes: {
                                                target: '_blank'
                                            }
                                        }
                                    ]
                                }
                            })
                            .then(editor => {
                                el.value.editor = editor;
                                editor.model.document.on('change:data', (eventInfo) => {
                                    input.value = editor.getData();
                                });
                                //
                                //     var oldNode = editor.sourceElement.parentNode.querySelector(".ck-file-dialog-button > button");
                                //     var newNode = oldNode.cloneNode(true);
                                //     oldNode.parentNode.insertBefore(newNode, oldNode);
                                //     oldNode.parentNode.removeChild(oldNode);
                                //
                                //     newNode.editor = editor;
                                //     newNode.addEventListener("click", handleImageButton);
                            })
                            .catch(error => {
                                console.error('There was a problem initializing the editor.', error);
                            });
                    }
                    watch(
                        () => props.value,
                        () => {
                            input.value = props.value;
                            el.value.editor && el.value.editor.setData(props.value);
                        },
                        {
                            immediate: true
                        }
                    );
                    tmpWatcher && tmpWatcher();
                },
                {
                    immediate: true
                }
            );
        });


        // const handleImageButton = (e) => {
        //     const target = e.currentTarget || e.target;
        //     const editor = target.editor;
        //
        //     studioibizz.editor.instance = editor;
        //
        //     studioibizz.ajax_popup.openFancybox(`/webcore/image/ckfinder?editor=${editor.id}`, true, true);
        // };


        return {
            trans,
            el,

            input,
            error,
            validateInput
        };
    },
}
</script>