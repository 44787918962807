<template>
    <div class="element" :data-required="this.required" :class="{ [this.type] : true, 'error' : !!error.error }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <template v-if="!this.editing || this.readonly">
            <pre class="input" v-html="highlightedJson"></pre>
        </template>
        <template v-else>
            <JsonEditor :data="json" :path="[]" @update="updateValue" />
        </template>
        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<style lang="scss">
.JsonField pre.input {
    overflow: auto; /* Adds scrollbars if content overflows */
    white-space: pre-wrap; /* Wraps text within the container */
    word-wrap: break-word; /* Breaks long words to fit the container */
    background-color: #2e3440; /* Dark background */
    color: #d8dee9; /* Default text color */
    font-size: 14px;

    .json-key {
        color: #81a1c1; /* Light blue for keys */
    }

    .json-string {
        color: #a3be8c; /* Green for strings */
    }

    .json-number {
        color: #b48ead; /* Purple for numbers */
    }

    .json-boolean {
        color: #d08770; /* Orange for booleans */
    }
}
</style>


<script>
import {ref, watch, reactive, inject, computed} from "vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import { resolveData, formatData } from "helpers/_data";
import { getDataAsync } from "helpers/_api";
import trans from "helpers/_translation";

// Config for API urls used in this vue template
import config from "config";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";
import JsonEditor from "formbuilder/elements/JsonFieldEditor.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        JsonEditor,
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {
        // Helpers
        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();
        registerValidator(input, props);

        const handleChange = () => {
            emit('value-change', {
                name: props.name,
                value: input.value
            });
        }

        const json = computed(() => {
            try {
                return JSON.parse(input.value);
            } catch (e) {
                return null;
            }
        });
        const highlightedJson = computed(() => {
            let target = json.value;
            if (typeof target !== "string") {
                target = JSON.stringify(target, null, 2);
            }
            return target
                .replace(/"([^"]+)":/g, '<span class="json-key">"$1"</span>:') // Format keys
                .replace(/: "([^"]+)"/g, ': <span class="json-string">"$1"</span>') // Format string values
                .replace(/: (\d+)/g, ': <span class="json-number">$1</span>') // Format numeric values
                .replace(/: (true|false|null)/g, ': <span class="json-boolean">$1</span>'); // Format boolean and null values
        });

        // Function to handle updates passed from the JsonEditor component
        const updateValue = (path, value) => {
            const tmpJson = JSON.parse(JSON.stringify(json.value));
            let target = tmpJson;
            for (let i = 0; i < path.length - 1; i++) {
                target = target[path[i]];
            }
            target[path[path.length - 1]] = value;

            input.value = JSON.stringify(tmpJson);
            handleChange();
        };

        watch(
            () => props.id,
            () => {
                input.value = JSON.stringify(props.value);
            },
            {
                immediate: true
            }
        );

        return {
            input,
            error,
            validateInput,
            trans,

            json,
            highlightedJson,

            updateValue
        };

        return {
            trans,
            input,
            error,
            resolveProps,
            validate,

            handleChange
        };
    },
}
</script>