<template>
    <img class="logo" :src="logo()" :alt="theme('title')">
</template>

<script>
import { defineComponent, inject } from "vue";

import { theme } from "config";
import { logo } from "helpers/_theme";

export default defineComponent({
    name: "app",
    components: {},
    setup() {

        return {
            logo,
            theme
        }
    }
});
</script>