<template>
    <div class="VuePopup PortalPopup" :class="classlist">
        <div class="inner">
            <slot />
            <button v-if="this.closeButton" class="closebtn" @click="handleTogglePopup()">
                <Icon name="close"></Icon>
            </button>
        </div>
        <div class="bg" @click="this.closeByBackground && handleTogglePopup()"></div>
    </div>
    <Teleport to="body">
        <PopupConfirm ref="popupConfirmRef" />
    </Teleport>
</template>

<script>
import { defineComponent, ref } from "vue";
import Icon from "components/icon.vue";
import PopupConfirm from "components/popupConfirm";

export default defineComponent({
    name: "Popup",
    props: {
        togglePopup : {
            required: true,
        },
        classlist: {
            type: String,
            required: false,
            default: ""
        },
        closeButton: {
            type: Boolean,
            required: false,
            default: true
        },
        closeByBackground: {
            type: Boolean,
            required: false,
            default: true
        },
        closeByConfirm: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        Icon,
        PopupConfirm
    },
    setup(props) {
        const popupConfirmRef = ref();

        const handleToggleConfirm = () => {
            return new Promise(async (resolve, reject) => {
                if (!props.closeByConfirm) {
                    return resolve(true);
                }
                let ok = await popupConfirmRef.value.show();
                if (ok) {
                    return resolve(true);
                } else {
                    return reject(false);
                }
            });
        };
        const handleTogglePopup = () => {
            return handleToggleConfirm().then(() => {
                return props.togglePopup();
            }).catch(() => {
                // Do nothing
                return;
            })
        };

        return {
            handleTogglePopup,
            popupConfirmRef
        }
    }
});
</script>
