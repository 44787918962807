<template>
    <div class="element" :data-required="this.required" :class="{ [this.type] : true, error : error.error }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <div v-if="!this.editing">
            <div class="Content" v-html="input" />
        </div>
        <div v-else>
            <textarea
                ref="el"
                :id="`field_${this.id}`"
                :name="this.name"
                :readonly="!this.editing || this.readonly"
                :placeholder="`${this.show_placeholder ? placeholder + (required ? ' *' : '') : ''}`"
                :autocomplete="this.autocomplete"
                :maxlength="this.maxlength"
                v-model="input"
                class="input"
                @keyup="validate"
                @blur="validate"
            />
        </div>
        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import { ref, watch, onMounted, computed } from "vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {
        const el = ref();

        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();
        registerValidator(input, props);

		onMounted(() => {

			watch(
				() => props.value,
				() => {
					input.value = props.value;
				},
				{
					immediate: true
				}
			);

			watch(
				() => el.value,
				() => {
					if (el.value && !el.value.editor) {
						ClassicEditor
							.create(el.value, {
								toolbar: {
									items: [
										'heading',
										'|',
										'bold',
										'italic',
										'|',
										'bulletedList',
										'numberedList',
										'|',
										'insertTable',
										'|',
										'undo',
										'redo'
									]
								},
								// image: {
								//     // Configure the available styles.
								//     styles: [
								//         'alignLeft', 'alignCenter', 'alignRight'
								//     ],
								//
								//     // Configure the available image resize options.
								//     resizeOptions: [
								//         {
								//             name: 'imageResize:original',
								//             label: 'Original',
								//             value: null
								//         },
								//         {
								//             name: 'imageResize:50',
								//             label: '50%',
								//             value: '50'
								//         },
								//         {
								//             name: 'imageResize:75',
								//             label: '75%',
								//             value: '75'
								//         }
								//     ],
								//     toolbar: [
								//         'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
								//         '|',
								//         'imageResize',
								//         '|',
								//         'imageTextAlternative'
								//     ]
								// },
								link: {
									// Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
									addTargetToExternalLinks: true,

									// Let the users control the "download" attribute of each link.
									decorators: [
										{
											mode: 'manual',
											label: 'Openen in nieuw venster',
											attributes: {
												target: '_blank'
											}
										}
									]
								}
							})
							.then(editor => {
								editor.model.document.on( 'change:data', (eventInfo) => {
									input.value = editor.getData();
								});
                                el.value.editor = editor;
								//
								//     var oldNode = editor.sourceElement.parentNode.querySelector(".ck-file-dialog-button > button");
								//     var newNode = oldNode.cloneNode(true);
								//     oldNode.parentNode.insertBefore(newNode, oldNode);
								//     oldNode.parentNode.removeChild(oldNode);
								//
								//     newNode.editor = editor;
								//     newNode.addEventListener("click", handleImageButton);
							})
							.catch(error => {
								console.error('There was a problem initializing the editor.', error);
							});
					}
				},
				{
					immediate: true
				}
			);

		});
        // const handleImageButton = (e) => {
        //     const target = e.currentTarget || e.target;
        //     const editor = target.editor;
        //
        //     studioibizz.editor.instance = editor;
        //
        //     studioibizz.ajax_popup.openFancybox(`/webcore/image/ckfinder?editor=${editor.id}`, true, true);
        // };

        const placeholder = computed(() => props.translate ? trans(props.placeholder) : props.placeholder);

        return {
            trans,
            el,

            input,
            error,
            resolveProps,
            validate,
            placeholder
        };
    },
}
</script>