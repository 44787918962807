<template>
    <header id="Header" v-if="isWorkflowportal || isMobile">
        <div v-if="isMobile" class="hamburger-menu">
            <button @click="toggleMenu">
                <Icon name="hamburger-menu" />
            </button>
        </div>
        <div v-if="isWorkflowportal" class="logocontainer" @click.prevent="handleHome" :data-label="env('version_label') || null">
            <Logo />
        </div>
    </header>
</template>

<script>
import { defineComponent, ref, watch, inject } from "vue";
import { useRouter } from "vue-router";
import trans from "helpers/_translation";
import Icon from "components/icon";

import { isMobile } from "helpers/_device";

// Components
import Logo from "elements/logo";

import {env} from "config";

export default defineComponent({
    name: "headerElement",
    components: {
        Logo,
        Icon
    },
    setup(props, { emit }) {

        const isWorkflowportal = inject("isWorkflowportal");

        const router = useRouter();

        const toggleMenu = () => {
            // Emit een event naar de bovenliggende component
            emit("toggleMenu");
        };

        const handleHome = () => {
            router.push("/");
        };

        return {
            env,
            trans,
            isMobile,
            toggleMenu,
            handleHome,
            isWorkflowportal
        };
    },
});
</script>
