<template>

    <div class="element RadioField" :data-required="this.required" :class="error.error ? 'error' : ''"
         :set="resolved_options = resolveOptions()">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <template v-if="Object.keys(resolved_options).length > 5">
            <div ref="dropdown" class="dropdown">
                <button class="btn dropdown-toggle" type="button" @click="handleDropdownClick($event)"
                        :disabled="!this.editing || this.readonly">
                    <span class="title" v-if="!input">{{ trans("Selecteer een ") + this.label }}</span>
                    <span class="title" v-else>{{ resolved_options[input] ?? input }}</span>
                </button>
                <div class="dropdown-menu" :class="{open: isDropdownActive}">
                    <div class="Radio_container">
                        <div class="Radio_element" v-for="(label, id) in resolved_options" :set="field_id = `field_${this.id}_${id}`">
                            <input
                                type="radio"
                                class="radio"
                                :checked="this.value === id"
                                :class="{'readonly': this.readonly}"
                                :id="field_id"
                                :name="this.name"
                                :readonly="!this.editing || this.readonly"
                                :autocomplete="this.autocomplete"
                                :value="id"
                                @change="handleChange"
                            />
                            <label class="label" :for="field_id" @click="handleClick($event)">
                                <span class="title">{{ label }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="Radio_container">
                <div class="Radio_element" :class="{float: this.float}" v-for="(label, id) in resolved_options" :set="field_id = `field_${this.id}_${id}`">
                    <input
                        type="radio"
                        class="radio"
                        :checked="this.value === id"
                        :class="{'readonly': this.readonly}"
                        :id="field_id"
                        :name="this.name"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        :value="id"
                    />
                    <label class="label" :for="field_id" @click="handleClick($event)">
                        <span class="title">{{ label }}</span>
                    </label>
                </div>
            </div>
        </template>
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<script>
import {ref, watch, computed} from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import {propDefaults} from "./_props";

// Our helpers
import {mergeDeep} from "helpers/_objects";
import trans from "helpers/_translation";

export default {
    props: mergeDeep(
        propDefaults,
        {
            show_none_option: {type: Boolean, required: false, default: false}
        }
    ),
    setup(props) {
        const dropdown = ref();
        const isDropdownActive = ref(false);
        const catchOutsideClick = (e, dropdown) => {
            // When user clicks menu — do nothing
            if (dropdown && (dropdown === e.target || dropdown.contains(e.target))) return false;

            // When user clicks outside of the menu — close the menu
            if (isDropdownActive.value && dropdown !== e.target && !dropdown.contains(e.target)) return true;
        };
        const handleDropdownClick = (e) => {
            isDropdownActive.value = !isDropdownActive.value;
            const closeListener = (e) => {
                if (catchOutsideClick(e, dropdown.value)) {
                    window.removeEventListener('click', closeListener);
                    isDropdownActive.value = false;
                }
            };
            if (isDropdownActive.value) {
                window.addEventListener('click', closeListener);
            } else {
                window.removeEventListener('click', closeListener);
            }
        };


        let input = ref("");
        const {error} = useFormValidation();
        // const validateInput = () => {
        //     if (props.required) {
        //         // validateTextField(props.label, input.value);
        //     }
        // };

        const resolveOptions = () => {
            return computed(() => {
                let options = (Object.values(props.options).length ? {...props.options} : JSON.parse(props.options_json || "[]"));
                if (props.show_none_option && !props.required) {
                    options = {
                        "": "Geen",
                        ...options
                    };
                }
                return options;
            }).value;
        };

        watch(
            () => props.value,
            () => {
                input.value = props.value;
            }, {immediate: true}
        );

        const handleClick = (e) => {
            const label = e.currentTarget || e.target;
            const radio = document.getElementById(label.getAttribute("for"));
            if (!radio) return;

            if (radio.checked) {
                radio.checked = false;
                e.preventDefault();
            }
        };

        const handleChange = (e) => {
            const target = e.currentTarget || e.target;
            if (target.checked) {
                input.value = target.value;
            }
        };

        return {
            trans,
            dropdown,
            isDropdownActive,
            handleDropdownClick,
            handleClick,

            resolveOptions,
            input,
            error,
            handleChange
            // , validateInput
        };
    },
}
</script>
