import {computed, watch} from "vue";

import {env} from "config";

import {role, portalStore} from "store/portal";

export const view_configuration = (name) => {
	return computed(() => {
		const tmpConfiguration = env(`view`);
		return tmpConfiguration?.find(a => a.name === name) ?? undefined;
	}).value;
};

const view_visible_by_role = (config) => {
	return computed(() => {
		if (!role.value) return false;
		if (config.visibility?.role) {
			let visible = true;

			// Define constants
			const all = config.visibility.role?.all ?? [];
			const any = config.visibility.role?.any ?? [];
			const none = config.visibility.role?.none ?? [];

			// Validate the data
			if (all.length) {
				visible = visible && all.every(r => role.value.includes(r));
			}
			if (any.length) {
				visible = visible && any.some(r => role.value.includes(r));
			}
			if (none.length) {
				visible = visible && none.every(r => !role.value.includes(r));
			}
			return visible;
		}
		return true;
	}).value;
};
// const view_visible_by_property = (config) => {
// 	return computed(() => {
// 		console.log("config.visibility?.property", config.visibility?.property);
// 		if (config.visibility?.property) {
// 			let visible = true;
//
// 			// Define constants
// 			const all = config.visibility.property?.all ?? [];
// 			const any = config.visibility.property?.any ?? [];
// 			const none = config.visibility.property?.none ?? [];
//
// 			console.log("company.value", company.value);
//
// 			// Validate the data
// 			if (all.length) {
// 				// visible = visible && all.every(r => role.value.includes(r));
// 			}
// 			if (any.length) {
// 				// visible = visible && any.some(r => role.value.includes(r));
// 			}
// 			if (none.length) {
// 				// visible = visible && none.every(r => !role.value.includes(r));
// 			}
// 			return visible;
// 		}
// 		return true;
// 	}).value;
// };

export const view_visible = (name) => {
	const tmpConfiguration = view_configuration(name);
	return computed(() => {
		if (!tmpConfiguration) return false;

		if (!view_visible_by_role(tmpConfiguration)) return false;
		// if (!view_visible_by_property(tmpConfiguration)) return false;

		return true;
	}).value;
};

export const checkDependecies = (name) => {
	return new Promise((resolve) => {
		let watcher;
		watcher = watch(
			() => view_configuration(name),
			(tmpConfiguration) => {
				if (!tmpConfiguration) return;

				const dependecies = Array.from(tmpConfiguration?.dependency ?? []);
				if (!dependecies.length) {
					resolve(true);
					watcher && watcher();
					return;
				}

				const promises = [];
				const watchers = {};
				dependecies.forEach(dependecy => {
					promises.push(new Promise(dependencyResolve => {
						watchers[dependecy] = watch(
							() => portalStore[dependecy],
							(result) => {
								// Loaded but undefined
								if (typeof result === "undefined") {
									dependencyResolve(false);
									watchers[dependecy] && watchers[dependecy]();
									return;
								}
								// Not yet loaded
								if (!result) return;
								// Loaded and not empty
								dependencyResolve(true);
								watchers[dependecy] && watchers[dependecy]();
								return;
							},
							{
								immediate: true
							}
						);
					}));
				});

				Promise.all(promises).then((results) => {
					// Make sure every dependecy was met
					resolve(results.every(r => r));
				});

				watcher && watcher();
			}, {
				immediate: true
			}
		);
	});
};

export const checkVisibility = (name) => {
	return new Promise((resolve) => {
		let watcher;
		watcher = watch(
			() => [role.value, env("view")],
			(values) => {
				if (values.some(a => typeof a === "undefined")) return;
				resolve(view_visible(name));
				if (!role.value) return;
				watcher && watcher();
			},
			{
				immediate: true
			}
		);

	});
};
