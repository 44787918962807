<template>
    <div class="element UploadField file-only" :data-required="this.required" :class="error.error ? 'error' : ''">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <ul class="block-grid one-up filelist">
            <li v-for="upload in componentData.upload">
                <a :href="upload._meta?.url" target="_blank">
                    <span class="title">{{ upload.title }}&nbsp;</span><Icon name="view" />
                </a>
                <button type="button" class="ico-button delete" @click.prevent="handleDelete(upload)" v-if="this.editing && !this.readonly">
                    <Icon name="delete" />
                </button>
            </li>
        </ul>
        <file-upload :readonly="!this.editing || this.readonly" :module_object="componentData.module_object" :item_id="componentData.item_id" :allowed_file_types="componentData.allowed_file_types" @update-upload="updateUpload"/>
        <input
            class="input"
            type="hidden"
            :id="`field_${this.id}`"
            :name="this.name"
            :readonly="!this.editing || this.readonly"
            :placeholder="this.placeholder"
            :autocomplete="this.autocomplete"
            :maxlength="this.maxlength"
            v-model="input"
            @keyup="validateInput"
            @blur="validateInput"
        />
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
    <Teleport to="body">

        <Popup v-if="popupVisibility.confirm" :closeButton="false" :togglePopup="() => togglePopup('confirm')" classlist="Confirm Detail small justified">
            <div class="Heading">
                <h1 class="h1">{{ trans("Wilt u deze actie uitvoeren?" )}}</h1>
            </div>
            <div class="inner">
                <nav>
                    <button type="button" class="more" @click.prevent="handleConfirmClick">
                        <span class="title">{{ trans("Ja") }}</span>
                    </button>
                    <button type="button" class="more outline" @click.prevent="togglePopup('confirm')">
                        <span class="title">{{ trans("Annuleren") }}</span>
                    </button>
                </nav>
            </div>
        </Popup>
    </Teleport>
</template>

<script>
import {reactive, ref, watch, inject} from "vue";

// Config for API urls used in this vue template
import { config, apiHostname } from "config";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";

import { setWorkflowpropertyfield }  from "helpers/_workflowpropertyfield";

// Helper functions
import { getDataAsync } from "helpers/_api";
import { resolveData } from "helpers/_data";
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

// Our components
import FileUpload from "components/file-upload";
import Icon from "components/icon.vue";
import Popup from "components/popup";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        Icon,
        Popup,
        FileUpload
    },
    setup(props) {
        let input = ref(props.value);
        const componentData = reactive({
            "module_object": props.module_object,
            "item_id": props.id,
            "allowed_file_types": [
                "application/postscript",
                "application/pdf",
                "application/rtf",
                "application/octet-stream",
                "application/x-compressed",
                "application/zip",
                "text/plain",
                "text/xml",
                "text/vcard",
                "video/webm",
                "video/mp4",
                "video/ogg",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
                "application/vnd.ms-word.document.macroEnabled.12",
                "application/vnd.ms-word.template.macroEnabled.12",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
                "application/vnd.ms-excel.sheet.macroEnabled.12",
                "application/vnd.ms-excel.template.macroEnabled.12",
                "application/vnd.ms-excel.addin.macroEnabled.12",
                "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
                "application/vnd.ms-powerpoint",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "application/vnd.openxmlformats-officedocument.presentationml.template",
                "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                "application/vnd.ms-powerpoint.addin.macroEnabled.12",
                "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
                "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
                "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
            ].join(','),
            "upload": []
        });

        const moduleData = inject("moduleData");
        const workflowcardData = inject("workflowcardData");
        watch(
            () => moduleData,
            () => {
                if (moduleData?.module_object === "Workflowcard") {
                    componentData.module_object = moduleData.module_object;
                    componentData.item_id = moduleData.workflowcard_id;
                }
            },
            {
                immediate: true,
                deep: true
            }
        );

        const getUpload = () => {
            if (!input.value) return;

            let ids = [];
            let jsonString = [];
            try {
                jsonString = JSON.parse(input.value);
                if (Array.isArray(jsonString)) {
                    ids = jsonString;
                } else {
                    ids.push(`${jsonString}`);
                }
            } catch (e) {
                ids.push(input.value);
            }
            if (jsonString.length) {
                ids = jsonString;
            }
            if (!ids.length) return;

            getDataAsync(`${apiHostname}${config.value.upload.url}`, [
                [
                    "FieldFilter",
                    {
                        "column": "id",
                        "value": ids
                    }
                ],
                [
                    "FieldFilter",
                    {
                        "column": "state",
                        "value": "Online"
                    }
                ]
            ]).then(result => {
                componentData.upload = resolveData(result);
            });
        };

        const appData = inject("generalData");
        const resolvePropertyfieldByName = (name) => {
            return appData.workflowpropertyfields.find(field => field.name === name);
        };
        const setValue = (key, value) => {
            // Get our property field we submitted
            if (componentData.module_object !== "Workflowcard" || key === "files[]") return;
            const field = resolvePropertyfieldByName(key);
            if (!field) return;
            // Set field true if changes made, otherwise fallback to current state
            setWorkflowpropertyfield(workflowcardData, componentData.item_id, field.id, value);
        };

        const uploadRef = ref(1);
        const updateUpload = (tus_result, result) => {
            const ids = result.map(a => a.data.id);
            input.value = JSON.stringify(ids);

            setValue(props.name, JSON.stringify(ids));

            uploadRef.value += 1;
        };
        watch(
            () => uploadRef.value,
            getUpload,
            {
                immediate: true
            }
        );

        const {validateTextField, error} = useFormValidation();
        const validateInput = () => {
            if (props.required) {
                validateTextField(props.label, input.value);
            }
        };

        watch(
            () => props.value,
            () => {
                input.value = props.value;
                getUpload();
            }
        );

        let callbackProperties = {};
        const handleDelete = (upload) => {
            togglePopup("confirm");
            callbackProperties.upload = upload;
        };

        const handleConfirmClick = () => {
            // @TODO refactor, use real confirm. Fastest way for now
            const upload = callbackProperties.upload;
            if (!upload) return;

            const ids = JSON.parse(input.value) ?? [];
            if (Array.isArray(ids)) {
                input.value = JSON.stringify(ids.filter(id => id !== upload.id));
            } else {
                input.value = "";
            }
            setValue(props.name, input.value);
            componentData.upload = componentData.upload.filter(u => u.id !== upload.id);
            togglePopup("confirm");
            callbackProperties.upload = null;
        };
        const popupVisibility = reactive({
            confirm: false
        });
        const togglePopup = (trigger) => {
            popupVisibility[trigger] = !popupVisibility[trigger];
            popupVisibility[trigger] ? document.body.classList.add("VuePopupOpen") : document.body.classList.remove("VuePopupOpen");
            callbackProperties = {};
        };

        return {
            trans,
            componentData,
            input,
            error,
            validateInput,
            updateUpload,

            handleDelete,

            handleConfirmClick,
            togglePopup,
            popupVisibility
        };
    },
}
</script>