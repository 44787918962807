<template>
    <div class="AddressFieldElement" :data-required="this.required" :class="error.error ? 'error' : ''">
<!--        <label :for="`field_${this.id}_street`" v-if="this.label !== 'Adres'">{{ this.label }}</label>-->
        <div class="cs_formgrid">
            <div class="span6">
                <div class="element AddressField" :class="{required: this.required}">
                    <label :for="`field_${this.id}_street`">{{ trans("Straat") }}</label>
                    <input
                        class="input"
                        type="text"
                        :id="`field_${this.id}_street`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        v-model="addressRefs.street"
                        @keyup="validateInput"
                        @blur="validateInput"
                    />
                </div>
            </div>
            <div class="span3">
                <div class="element AddressField" :class="{required: this.required}">
                    <label :for="`field_${this.id}_housenumber`">{{ trans("Huisnummer") }}</label>
                    <input
                        class="input"
                        type="text"
                        :id="`field_${this.id}_housenumber`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        v-model="addressRefs.housenumber"
                        @keyup="validateInput"
                        @blur="validateInput"
                    />
                </div>
            </div>
            <div class="span3">
                <div class="element AddressField">
                    <label :for="`field_${this.id}_housenumber_suffix`">{{ trans("Toevoeging") }}</label>
                    <input
                        class="input"
                        type="text"
                        :id="`field_${this.id}_housenumber_suffix`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        v-model="addressRefs.housenumber_suffix"
                    />
                </div>
            </div>
            <div class="span6">
                <div class="element AddressField" :class="{required: this.required}">
                    <label :for="`field_${this.id}_zipcode`">{{ trans("Postcode") }}</label>
                    <input
                        class="input"
                        type="text"
                        :id="`field_${this.id}_zipcode`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        v-model="addressRefs.zipcode"
                        @keyup="validateInput"
                        @blur="validateInput"
                    />
                </div>
            </div>
            <div class="span6">
                <div class="element AddressField" :class="{required: this.required}">
                    <label :for="`field_${this.id}_place`">{{ trans("Plaats") }}</label>
                    <input
                        class="input"
                        type="text"
                        :id="`field_${this.id}_place`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        v-model="addressRefs.place"
                        @keyup="validateInput"
                        @blur="validateInput"
                    />
                </div>
            </div>
            <div class="span12">
                <div class="element AddressField" :class="{required: this.required}">
                    <label :for="`field_${this.id}_country`">{{ trans("Land") }}</label>
                    <select
                        class="input"
                        :id="`field_${this.id}_country`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        v-model="addressRefs.country"
                    >
                        <option value="NL">{{ trans("Nederland") }}</option>
                        <option value="BE">{{ trans("België") }}</option>
                        <option value="FR">{{ trans("Frankrijk") }}</option>
                        <option value="DE">{{ trans("Duitsland") }}</option>
                        <option value="GB">{{ trans("Verenigd Koningrijk") }}</option>
                        <option value="IT">{{ trans("Italië") }}</option>
                        <option value="ES">{{ trans("Spanje") }}</option>
                    </select>
                </div>
            </div>
        </div>
        <input
            type="hidden"
            readonly
            :name="this.name"
            :autocomplete="false"
            v-model="input"
        />
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<script>
import {ref, watch, reactive} from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";
import trans from "helpers/_translation";

// Our helpers
import { mergeDeep } from "helpers/_objects";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    setup(props) {
        /*
        "country",
        "zipcode",
        "street",
        "housenumber",
        "housenumber_suffix",
        "place",
        "province"
        */

        const input = ref("");
        const {validateTextField, error} = useFormValidation();
        const validateInput = (e) => {
            if (props.required) {
                validateTextField(props.label, e.target.value);
            }
        };

        // Place is unused at the moment
        const addressRefs = reactive({
            street: null,
            housenumber: null,
            housenumber_suffix: null,
            zipcode: null,
            place: null,
            province: null,
            country: null
        });


        watch(
            () => props.value,
            () => {
                input.value = props.value;
                if (props.value) {
                    const addressData = (typeof props.value === 'string') ? JSON.parse(props.value) : props.value;
                    Object.keys(addressRefs).forEach((key, index) => {
                        addressRefs[key] = addressData[key];
                    });
                }
                if (!addressRefs.country) {
                    addressRefs.country = "NL";
                }
            },
            {
                immediate: true
            }
        );

        const setAddress = () => {
            input.value = JSON.stringify(addressRefs);
        };

        Object.keys(addressRefs).forEach(key => {
            watch(
                () => addressRefs[key],
                setAddress
            );
        });



        return {
            trans,
            input,
            error,
            validateInput,

            addressRefs
        };
    },
}
</script>