<template>
    <div class="maincontainer">
        <div class="maincolumn">
            <div class="Heading">
                <div class="left">
                    <h1 class="h1">{{ trans(pageTitle) }}</h1>
                </div>
                <div class="right">
<!--                    Settings button?-->
                </div>
            </div>
            <div class="error" v-if='unauthorized || (typeof contact === "undefined")'>
                {{ trans("Het lijkt erop dat er geen contactpersoon is gekoppeld.")}}<br>
                {{ trans("Mocht dit probleem aanhouden, neem dan contact op met de beheerder.") }}
            </div>
            <div v-else class="Block Widget Overview">
                <div class="Quicklinks">
                    <button v-for="quicklink in env('quicklink')" class="Quicklink useHand" type="button" @click.prevent="handleClick(quicklink.name)">
                        <icon :name="quicklink.icon" />
                        <div class="Heading">
                            <div class="title">{{ trans(quicklink.title) }}</div>
                            <div class="subtitle">{{ trans(quicklink.subtitle) }}</div>
                        </div>
                    </button>
                </div>
                <div class="Grid block-grid">
                    <div class="Item Medium Profile" v-if="dashboard_visible('Profile')">
                        <div class="avatar">
                            <figure>
                                <img v-if="user_avatar()" :src="user_avatar()" alt="" />
                                <Icon v-else name="profile" />
                            </figure>
                        </div>
                        <div class="heading">{{ trans("Welkom") }} {{ value(contact, env("variables.contact.properties.fullname") ) }}</div>
                        <nav>
                            <button class="more" type="button" @click.prevent="handleClick('Dashboard profile')">
                                <div class="title">{{ trans("%s bewerken", trans("Profiel")) }}</div>
                            </button>
                        </nav>
                    </div>
                    <div class="Item Medium Support" v-if="dashboard_visible('Support')">
                        <Icon name="support" />
                        <div class="heading">{{ trans("Hoe kunnen we je helpen?") }}</div>
                        <nav>
                            <a v-if="theme('phone')" :href="`tel:${phonenumber(theme('phone'))}`" class="more bg3" target="_blank">
                                <div class="title">{{ trans("Bellen") }}</div>
                            </a>
                            <a v-if="theme('email')" :href="`mailto:${theme('phone')}`" class="more outline" target="_blank">
                                <div class="title">{{ trans("Mailen") }}</div>
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, inject, watch, computed} from "vue";

import Icon from "components/icon";
import trans from "helpers/_translation";
import { contact, value, user_avatar, unauthorized, portalStore } from "store/portal";
import { theme, env } from "config";
import { view_configuration } from "store/view";

import { useRoute, useRouter } from "vue-router";
import { phonenumber } from "helpers/_theme";

export default defineComponent({
    name: "dashboardView",
    components: {
        Icon
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const pageTitle = computed(() => {
            return view_configuration(route.name)?.title ?? "";
        });

        const handleClick = (name, query = {}) => {
            router.push({
                name: name,
                query: query
            });
        };

        const dashboard_visible = (name) => {
            return computed(() => {
                if (env("dashboard")) {
                    return env("dashboard").some(a => a.name === name);
                }
                return false;
            }).value;
        };

        return {
            env,
            theme,
            phonenumber,

            value,

            trans,
            pageTitle,

            portalStore,
            unauthorized,
            contact,
            user_avatar,

            dashboard_visible,

            handleClick
        }
    }
});
</script>
