export const propDefaults = {
    // Initial properties
    module_object: {type: String, required: false, default: ""},
    id: {type: (Number, String), required: true, default: ""},
    name: {type: String, required: true},
    label: {type: String, required: true, default: ""},
    placeholder: {type: String, required: false, default: ""},
    autocomplete: {type: Boolean, required: false, default: false},
    maxlength: {type: String, required: false, default: ""},
    required: {type: Boolean, required: false, default: false},
    readonly: {type: Boolean, required: false, default: false},
    float: {type: Boolean, required: false, default: true},

    // Data properties
    value: {type: String, required: false, default: null},
    options: {type: Object, required: false, default: {}},
    options_json: {type: String, required: false, default: ""},

    // Extra parameters, used for external behaviour
    editing: {type: Boolean, required: false, default: true},

    // Extra parameters for template behaviour, passed by fields.vue
    show_label: {type: Boolean, required: false, default: true},
    show_placeholder: {type: Boolean, required: false, default: false},
    show_placeholder_on_input: {type: Boolean, required: false, default: true},
    show_error_message: {type: Boolean, required: false, default: true},
    show_state: {type: Boolean, required: false, default: true},
    show_tooltip_inline: {type: Boolean, required: false, default: false}
};