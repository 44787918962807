<template>
    <div class="element TextField" :data-required="this.required" :class="{ error: error.error, readonly: inputOptions.readonly }">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <vue-tel-input
            ref="telInput"
            mode="international"
            :onlyCountries="['NL','BE','DE','FR']"
            defaultCountry="NL"
            :inputOptions="inputOptions"
            :dropdownOptions="dropdownOptions"
        >
            <template v-slot:arrow-icon>
                <span class="fa icon"><Icon name="angle" /></span>
            </template>
        </vue-tel-input>
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<style>
    .vue-tel-input {
        display: flex; flex-direction: row; justify-content: flex-start; align-items:stretch; width:100%;
        gap: 10px;
        .vti__dropdown {

            border: var(--form-input_borderwidth,#{$input_borderwidth}) solid var(--form-input_border,#{$input_border});
            background: var(--form-input_background,#{$input_background});
            color: var(--form-input_color,#{$input_color});
            padding: var(--form-input_vpadding,#{$input_vpadding}) var(--form-input_hpadding,#{$input_hpadding});
            border-radius: var(--form-input_radius,#{$input_radius});
            font-size: var(--form-input_fontsize,#{$input_fontsize});
            font-family: var(--form-input_font,#{$input_font});
            font-weight: var(--form-input_fontweight,#{$input_fontweight});
            line-height: var(--form-input_lineheight,#{$input_lineheight});
            display: flex;
            .vti__selection {
                display: flex;
                align-items: center;
                &:hover { color: #EB4628; cursor: pointer; }
            }
            .fa {
                font-size: 10px;
                padding-right: 5px;
            }
        }
        .vti__country-code {
            min-width:35px; display:inline-block;
        }
        .vti__dropdown-list {
            list-style:none; margin:0; z-index:105;
            position:absolute; left:0; border-radius:$radius;
            border: 1px solid var(--border, #{$border-light});
            border-radius: 10px;
            background: var(--background, #fff);
            box-shadow: var(--box-shadow);
            padding: 15px;
            &, * {
            @include select;
           }
            .vti__dropdown-item {
                padding:5px 0;
                opacity:0.8;
                cursor:pointer;
                font-size: 14px;
                &:hover { opacity:1; }
                &.highlighted { opacity:1; }
            }
            &.below {
                top:100%;
             }
             &.above {
                bottom:100%;
              }
        }
    }
    .readonly > .vue-tel-input .vti__dropdown {
        display:none;
    }
</style>

<script>
import {ref, watch, reactive, onMounted} from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";

// External components
import { VueTelInput } from 'vue3-tel-input';
import trans from "helpers/_translation";
import Icon from "components/icon";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        VueTelInput,
        Icon
    },
    setup(props) {
        const telInput = ref();

        // See https://github.com/victorybiz/vue3-tel-input/blob/master/src/components/vue-tel-input.vue
        const inputOptions = reactive({
            name: props.name,
            id: `field_${props.id}`,
            styleClasses: { "input": true },
            placeholder: props.placeholder,
            autocomplete: props.autocomplete,
            maxlength: (props.maxlength || 15),
            type: "text"
        });
        const dropdownOptions = reactive({
            showFlags: false,
            showDialCodeInSelection: true,
            showDialCodeInList: true
        });

        let input = ref("");
        const {validateTextField, error} = useFormValidation();
        const validateInput = () => {
            if (props.required) {
                validateTextField(props.label, input.value);
            }
        };

        watch(
            () => props.editing,
            () => {
                inputOptions.readonly = (!props.editing || props.readonly);
            },
            {
                immediate: true
            }
        );

        onMounted(() => {
            let init = false;
            watch(
                () => props.value,
                (val) => {
                    if (!val || init) return;
                    init = true;
                    telInput.value && (telInput.value.phone = props.value || "");
                },
                {
                    immediate: true
                }
            );
            watch(
                () => telInput.value,
                (input) => {
                    if (!input) return;
                    telInput.value && (telInput.value.phone = props.value || "");
                }
            );
        });

        return {
            trans,
            telInput,
            input,
            inputOptions,
            dropdownOptions,
            error,
            validateInput
        };
    },
}
</script>
