<template>
    <footer id="Footer">
        © {{ theme('title') }}
    </footer>
</template>

<script>
import { defineComponent } from "vue";

import { theme } from "config";

export default defineComponent({
    name: "footerElement",
    props: {},
    components: {},
    setup() {
        return {
            theme
        };
    },
});
</script>
