<template>
    <div class="element HiddenField hidden">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <input
            type="text"
            readonly
            autocomplete="false"
            :id="`field_${this.id}`"
            :name="this.name"
            v-model="input"
        />
    </div>
</template>

<script>
import {ref, watch} from "vue";

// Our form helpers
import { propDefaults } from "./_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    setup(props) {
        let input = ref("");
        watch(
            () => props.value,
            () => {
                input.value = props.value;
            },
            {
                immediate: true
            }
        );

        return {input};
    },
}
</script>