<template>
    <div class="maincontainer">
        <div class="maincolumn">
            <h1 class="h1">{{ trans("Settings") }}</h1>
        </div>

        <button type="button" @click.prevent="toggleMode()">
            <Icon name="darkmode" />
            <span class="title">{{ textMode }}</span>
        </button>
    </div>
</template>

<script>
import {defineComponent, inject, watch, computed} from "vue";

import Icon from "components/icon";
import trans from "helpers/_translation";
import {env} from "config";
import { getSetting, storeSetting, matchSetting } from 'store/settings';

export default defineComponent({
    name: "dashboardSettings",
    components: {
        Icon
    },
    setup() {

        const toggleMode = inject("toggleMode");

        const textMode = computed(() => {
            return matchSetting("layout", "mode", "light") ? trans('Donkere modus') : trans('Lichte modus');
        });

        return {
            trans,

            toggleMode,
            textMode,
        }
    }
});
</script>
