<template>
    <div class="json-editor">
        <div v-for="(value, key) in data" :key="key" class="json-item" :class="{ 'array': Array.isArray(data) }">
            <span class="json-key" :data-title="key">{{ Array.isArray(data) ? (Number(key) + 1) : key }}:</span>
            <template v-if="isObject(value)">
                <JsonEditor :data="value" :path="[...path, key]" @update="(subPath, val) => updateValue(subPath, val)" />
            </template>
            <template v-else-if="Array.isArray(value)">
                <div class="json-array">
                    <JsonEditor v-for="(item, index) in value" :key="index" :data="item" :path="[...path, key, index]" @update="(subPath, val) => updateValue(subPath, val)" />
                </div>
            </template>
            <template v-else>
                <input
                    :value="value"
                    :type="inputType(key, value)"
                    :disabled="inputDisabled(key, value)"
                    class="input json-value"
                    @input="updateValue([...path, key], $event.target.type === 'checkbox' ? $event.target.checked : $event.target.value)"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs, computed } from 'vue';

export default defineComponent({
    name: 'JsonEditor',
    props: {
        data: {
            type: [Object, Array],
            required: true
        },
        path: {
            type: Array,
            required: true
        }
    },
    emits: ['update'],
    setup(props, { emit }) {
        const data = reactive(props.data);

        const isObject = (value) => typeof value === 'object' && value !== null && !Array.isArray(value);

        const inputType = (key, value) => {
            return computed(() => {
                if (["notes", "description", "content"].includes(key)) return 'textarea';
                if (inputDisabled(key, value)) return 'text';
                if (typeof value === 'number') return 'number';
                if (typeof value === 'boolean') return 'checkbox';
                return 'text'; // Default to text for strings
            }).value;
        };
        const inputDisabled = (key, value) => {
            return computed(() => {
                if ((typeof key === "string")
                    && (key.endsWith("_id") || key.endsWith("_code" || key.endsWith("_name")))
                ) {
                    return true;
                }
                return false;
            }).value;
        };


        // Function to emit updated values with correct path
        const updateValue = (path, value) => {
            // Convert value to appropriate type if necessary
            const currentValue = data[path[path.length - 1]];
            if (typeof currentValue === 'number') value = Number(value);
            else if (typeof currentValue === 'boolean') value = Boolean(value);

            emit('update', path, value);
        };


        return {
            ...toRefs(data),
            isObject,
            inputType,
            inputDisabled,
            updateValue
        };
    }
});
</script>

<style lang="scss">
.JsonField {
    --form-input_vpadding: 5px;
    --form-input_hpadding: 5px;
    .json-editor {
        margin-left: 20px; width:100%;
        font-size:var(--form-label_fontsize, 0.875rem);
    }
    > .json-editor {
        margin-left:0;
    }

    .json-item {
        margin-bottom: 3px;
        display:flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        &.array {
            flex-direction:column;
        }
        .json-editor {
            width:calc(100% - 20px);
        }
    }
    .json-key {
        font-weight: bold;
        text-transform: capitalize;
        margin-bottom:5px; margin-right:0;
    }
    .json-array {
        .json-item {
            flex-direction:row;
            align-items: flex-start;
            justify-content:flex-start;
            .json-key {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width:30%;
                margin-right: 10px;
                margin-bottom:0;
            }
        }
    }
    .json-value {
        flex:1;
    }
    .json-array {
        margin-left: 5px;
        // add a counter
        counter-reset: array-counter;
        .json-editor {
            margin-left:0;
            counter-increment: array-counter;
            padding-left:30px;
            position:relative;
            margin-bottom:20px;
            &::before {
                content: counter(array-counter) ":";
                position:absolute; left:0;
            }
        }
    }
}
</style>
