<template>
    <div class="element CheckboxField" :data-required="this.required" :class="error.error ? 'error' : ''"
         :set="resolved_options = resolveOptions()">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <template v-if="Object.keys(resolved_options).length > 5">
            <div ref="dropdown" class="dropdown">
                <button class="btn dropdown-toggle" type="button" @click="handleDropdownClick($event)"
                        :disabled="!this.editing || this.readonly">
                    <span class="title" v-if="!actives.length">{{ trans("Selecteer een ") + this.label }}</span>
                    <span class="title" v-else>{{ formatActives(actives) }}</span>
                </button>
                <div class="dropdown-menu" :class="{open: isDropdownActive}">
                    <div class="Checkbox_container">
                        <div class="Checkbox_element" v-for="(label, id) in resolved_options">
                            <input
                                type="checkbox"
                                class="checkbox"
                                :checked="actives.includes(id)"
                                :class="{'readonly': this.readonly}"
                                :id="`field_${this.id}_${id}`"
                                :name="this.name"
                                :readonly="!this.editing || this.readonly"
                                :autocomplete="this.autocomplete"
                                :value="id"
                                @change="handleChange"
                            />
                            <label class="label" :for="`field_${this.id}_${id}`">
                                <span class="title">{{ label }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="Checkbox_container">
                <div class="Checkbox_element" :class="{float: this.float}" v-for="(label, id) in resolved_options">
                    <input
                        type="checkbox"
                        class="checkbox"
                        :checked="actives.includes(id)"
                        :class="{'readonly': this.readonly}"
                        :id="`field_${this.id}_${id}`"
                        :name="this.name"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        :value="id"
                    />
                    <label class="label" :for="`field_${this.id}_${id}`">
                        <span class="title">{{ label }}</span>
                    </label>
                </div>
            </div>
        </template>
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<script>
import {ref, watch} from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import {propDefaults} from "./_props";

// Our helpers
import {mergeDeep} from "helpers/_objects";

import { formatActives } from "helpers/_formatting";
import trans from "helpers/_translation";

export default {
    props: mergeDeep(
        propDefaults,
        {
            show_none_option: {type: Boolean, required: false, default: false}
        }
    ),
    setup(props) {
        const dropdown = ref();
        const isDropdownActive = ref(false);
        const catchOutsideClick = (e, dropdown) => {
            // When user clicks menu — do nothing
            if (dropdown === e.target || dropdown.contains(e.target)) return false;

            // When user clicks outside of the menu — close the menu
            if (isDropdownActive.value && dropdown !== e.target && !dropdown.contains(e.target)) return true;
        };
        const handleDropdownClick = (e) => {
            isDropdownActive.value = !isDropdownActive.value;
            const closeListener = (e) => {
                if (catchOutsideClick(e, dropdown.value)) {
                    window.removeEventListener('click', closeListener);
                    isDropdownActive.value = false;
                }
            };
            if (isDropdownActive.value) {
                window.addEventListener('click', closeListener);
            } else {
                window.removeEventListener('click', closeListener);
            }
        };

        const actives = ref([]);
        const {error} = useFormValidation();
        // const validateInput = () => {
        //     if (props.required) {
        //         // validateTextField(props.label, input.value);
        //     }
        // };

        const resolveOptions = () => {
            let options = (Object.values(props.options).length ? {...props.options} : JSON.parse(props.options_json || "[]"));
            if (props.show_none_option && !props.required) {
                options = {
                    "": "Geen",
                    ...options
                };
            }
            return options;
        };

        watch(
            () => props.value,
            () => {
                try {
                    actives.value = JSON.parse(props.value) ?? [];
                } catch (e) {
                    // is not a valid JSON string
                    if (Array.isArray(props.value)) {
                        actives.value = props.value;
                    } else {
                        actives.value = [props.value];
                    }
                }
                if (typeof actives.value === 'number') {
                    actives.value = [`${actives.value}`];
                }
            }, {immediate: true}
        );

        const handleChange = (e) => {
            const target = e.currentTarget || e.target;
            if (target.checked) {
                !actives.value.includes(target.value) && actives.value.push(target.value);
            } else {
                actives.value = actives.value.filter(a => a !== target.value);
            }
        };

        return {
            trans,
            dropdown,
            isDropdownActive,
            handleDropdownClick,

            resolveOptions,
            actives,
            error,
            formatActives,
            handleChange
            // , validateInput
        };
    },
}
</script>
