<template>
    <div class="maincontainer">
        <div class="maincolumn">
            <div class="Heading">
                <div class="left">
                    <h1 class="h1">{{ trans(title) }}</h1>
                </div>
                <div class="right" v-if="!componentData.loading && componentData.id">
                    <button type="button" class="more-inline">
                        <span class="title">{{ trans("Cancel") }}</span>
                    </button>
                    <button type="button" class="more outline">
                        <Icon name="downloaden" />
                        <span class="title">{{ trans("Downloaden") }}</span>
                    </button>
                </div>
            </div>
            <div class="Block Invoice Overview">
                <div class="inner" v-if="componentData.loading">
                    {{ trans("Aan het laden") }}...
                </div>
                <div class="inner" v-if="!componentData.loading && !componentData.id">
                    {{ trans("Momenteel is er geen koppeling gelegd.")}}
                </div>
                <div class="inner" v-if="!componentData.loading && componentData.id">
                    <table>
                        <thead>
                        <tr>
                            <th class="tr-medium">Datum</th>
                            <th class="tr-big">Project</th>
                            <th class="tr-medium">Nummer</th>
                            <th class="tr-medium">Bedrag</th>
                            <th class="tr-medium">Status</th>
                            <th>Downloaden</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="tr-medium">jan, 13 2024</td>
                            <td class="tr-big">SEA - Pakket Zilver</td>
                            <td class="tr-medium">2024 / 4352</td>
                            <td class="tr-medium">€ 234,56</td>
                            <td class="tr-medium">
                                <div class="status">
                                    <span class="color green"></span>
                                    <span class="label">Betaald</span>
                                </div>
                            </td>
                            <td>
                                <button class="download">
                                    <Icon name="downloaden" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="tr-medium">jan, 13 2024</td>
                            <td class="tr-big">Mediabeheer - Pakket B</td>
                            <td class="tr-medium">2024 / 4323</td>
                            <td class="tr-medium">€ 3.500,-</td>
                            <td class="tr-medium">
                                <div class="status">
                                    <span class="color red"></span>
                                    <span class="label">Te laat</span>
                                </div>
                            </td>
                            <td>
                                <button class="download">
                                    <Icon name="downloaden" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="tr-medium">jan, 13 2024</td>
                            <td class="tr-big">Inrichting Brevo Account</td>
                            <td class="tr-medium">2024 / 2134</td>
                            <td class="tr-medium">€ 1.234,56</td>
                            <td class="tr-medium">
                                <div class="status">
                                    <span class="color blue"></span>
                                    <span class="label">Niet betaald</span>
                                </div>
                            </td>
                            <td>
                                <button class="download">
                                    <Icon name="downloaden" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="tr-medium">jan, 13 2024</td>
                            <td class="tr-big">Webdesign</td>
                            <td class="tr-medium">2024 / 4352</td>
                            <td class="tr-medium">€ 7.344,56</td>
                            <td class="tr-medium">
                                <div class="status">
                                    <span class="color orange"></span>
                                    <span class="label">Gecrediteerd</span>
                                </div>
                            </td>
                            <td>
                                <button class="download">
                                    <Icon name="downloaden" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="tr-medium">jan, 13 2024</td>
                            <td class="tr-big">Mediabeheer - Pakket B</td>
                            <td class="tr-medium">2024 / 4323</td>
                            <td class="tr-medium">€ 3.500,-</td>
                            <td class="tr-medium">
                                <div class="status">
                                    <span class="color red"></span>
                                    <span class="label">Te laat</span>
                                </div>
                            </td>
                            <td>
                                <button class="download">
                                    <Icon name="downloaden" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="tr-medium">jan, 13 2024</td>
                            <td class="tr-big">Inrichting Brevo Account</td>
                            <td class="tr-medium">2024 / 2134</td>
                            <td class="tr-medium">€ 1.234,56</td>
                            <td class="tr-medium">
                                <div class="status">
                                    <span class="color blue"></span>
                                    <span class="label">Niet betaald</span>
                                </div>
                            </td>
                            <td>
                                <button class="download">
                                    <Icon name="downloaden" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="tr-medium">jan, 13 2024</td>
                            <td class="tr-big">Webdesign</td>
                            <td class="tr-medium">2024 / 4352</td>
                            <td class="tr-medium">€ 7.344,56</td>
                            <td class="tr-medium">
                                <div class="status">
                                    <span class="color orange"></span>
                                    <span class="label">Gecrediteerd</span>
                                </div>
                            </td>
                            <td>
                                <button class="download">
                                    <Icon name="downloaden" />
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, watch, computed, reactive, onMounted, onUnmounted} from "vue";
import { useRoute } from "vue-router";

import Icon from "components/icon";
import trans from "helpers/_translation";
import {env} from "config";

import { company, value } from "store/portal";
import { view_configuration, checkDependecies } from "store/view";

export default defineComponent({
    name: "dashboardSettings",
    components: {
        Icon
    },
    setup() {
        const route = useRoute();

        const componentData = reactive({
            loading: true,
            provider: "teamleader",
            id: null
        });

        const config = computed(() => {
            return view_configuration(route.name);
        });
        const title = computed(() => {
            return config.value?.title ?? "";
        });

        let companyWatcher;
        let providerWatcher;
        onMounted(() => {

            checkDependecies(route.name).then((valid) => {
                if (!valid) {
                    console.log("Dependecies invalid");
                    componentData.loading = true;
                    componentData.id = undefined;
                }
            });

            companyWatcher = watch(
                () => company.value,
                (companyValue) => {
                    if (!companyValue) return;
                    componentData.id = value(company.value, env(`variables.company.properties.${componentData.provider}`));
                },
                {
                    immediate: true
                }
            );
            providerWatcher = watch(
                () => componentData.id,
                (providerId) => {
                    if (providerId === null) return;
                    if (providerId === "") {
                        componentData.loading = false;
                        return;
                    }
                    // @TODO add api call to get invoices, if no id was set
                    console.log("providerId", componentData.provider, providerId);
                    componentData.loading = false;
                },
                {
                    immediate: true
                }
            );
        });
        onUnmounted(() => {
            companyWatcher && companyWatcher();
            providerWatcher && providerWatcher();
        });

        // const example = computed(() => {
        //     return env("example.test");
        // });

        return {
            // example
            trans,
            config,
            title,

            componentData
        }
    }
});
</script>
