import { device } from "helpers/_device";

import trans from "helpers/_translation";

export const getAuthenticationForm = (type = "login") => {
	const fields = [
		{
			"type": "EmailField",
			"name": "username",
			"label": "E-mailadres",
			"required": true,
			"autocomplete": "email",
			"data": {
				"colclass": "span12"
			}

		}
	];
	if (type !== "forget") {
		fields.push({
			"type": "PasswordField",
			"name": "password",
			"label": "Wachtwoord",
			"required": type === "login",
			"autocomplete": "password",
			"data": {
				"colclass": "span12"
			}
		});
	}
	return fields;
};