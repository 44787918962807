<template>
    <div class="notifications" v-if="Object.values(notifications).length">
        <template v-for="notification in Object.values(notifications)">
            <div class="notification" :class="{ invisible: !notification.visible }">
                {{ notification.text }}
            </div>
        </template>
    </div>
</template>

<script>
import { defineComponent } from "vue";

import { notifications } from "store/notifications";

export default defineComponent({
    name: "Notifications",
    props: {},
    setup(props) {
        return {
            notifications
        }
    },
});
</script>