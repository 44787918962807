<template>
    <div class="element TitleField">
        <h3 class="h3 title">{{ this.label }}</h3>
    </div>
</template>

<script>
// Our form helpers
import { propDefaults } from "./_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    setup(props) {
        return {};
    },
}
</script>