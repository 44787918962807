<template>
    <div class="maincontainer">
        <div class="maincolumn">
            <div class="Heading">
                <div class="left">
                    <h1 class="h1">
                        <span class="main">{{ trans("Contact opnemen") }}</span>
                    </h1>
                </div>
            </div>
            <section class="Block contact Overview">
                <div class="block-grid">
                    <div class="Item call" v-if="theme('phone')">
                        <a :href="'tel:' + phonenumber(theme('phone'))">
                            <div class="inner">
                                <Icon name="phone" />
                                <span class="title">{{ theme('phone') }}</span>
                            </div>
                        </a>
                    </div>
                    <div class="Item mailto" v-if="theme('email')">
                        <a :href="'mailto:' + theme('email')">
                            <div class="inner">
                                <Icon name="mail" />
                                <span class="title">{{ theme('email') }}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {defineComponent, inject, watch, computed} from "vue";

import Icon from "components/icon";
import trans from "helpers/_translation";
import {theme} from "config";
import { phonenumber } from "helpers/_theme";

export default defineComponent({
    name: "dashboardSupport",
    components: {
        Icon
    },
    setup() {
        return {
            trans,
            theme,
            phonenumber
        }
    }
});
</script>
