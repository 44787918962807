<template>
    <form class="cs_form RemarkBar" @submit="handleSubmit">
        <div class="element MessageField">
            <input ref="input" type="text" class="input" name="content" autocomplete="off" v-model="content" :placeholder="trans('Een nieuw bericht typen ..')">
            <button class="more send" type="submit">
                <span class="title">{{ trans("Versturen") }}</span>
            </button>
        </div>
    </form>

    <div class="RemarkContent">
        <div class="Block Message Item" v-for="message in data" :class="{ own: own(message) }">
            <div class="MessageContent">
                <span class="title">
                    <span class="owner">{{ message.version_user }}</span>
                    <time datetime="{{ message.date_publish }}">{{ this.$filters.formatPostDate(message.date_publish) }}</time>
                    <span class="message-state read">
                        <Icon name="read" />
                    </span>
                </span>
                <span class="content">{{ message.content }}</span>
            </div>
            <div class="avatar">
                <figure>
                    <img v-if="user_avatar(message.owner)" :src="user_avatar(message.owner)" alt="" />
                    <Icon v-else name="profile" />
                </figure>
            </div>
        </div>
    </div>

    <Actions v-if="actionData.workflowevent_id" :workflowevent_id="actionData.workflowevent_id" @success="handleActionSuccess"/>
</template>

<script>
import {defineComponent, onMounted, reactive, ref, computed, watch, inject } from "vue";

// Helper functions
import { postDataAsync, getDataAsync } from "helpers/_api";
import { resolveData } from "helpers/_data";
import trans from "helpers/_translation";
import { addHistorylogRecord } from "helpers/_historylog";

import { contact, value, user_id, item, user_avatar } from "store/portal";
import { addNotification } from "store/notifications";

import Icon from "components/icon";
import Actions from "components/workflowcard/actions";

// Config for API urls used in this vue template
import { config, apiHostname, env } from "config";

export default defineComponent({
    name: "message-overview",
    components: {
        Icon,
        Actions
    },
    props: {
        item_id: {
            type: String,
            required: false
        },
        module_object: {
            type: String,
            required: false
        }
    },
    setup(props) {
        const viewData = inject("viewData");
        const data = ref([]);
        const content = ref("");
        const isOwn = ref(true);

        const module_object = computed(() => props.module_object);
        const item_id = computed(() => props.item_id);

        const input = ref(null);

        const actionData = reactive({
            "workflowevent_id": null
        });

        const initData = () => {
            data.value = [];
            const filters = [
                [
                    "FieldFilter",
                    {
                        "column": "react_module_object",
                        "value": module_object.value
                    }
                ],
                [
                    "FieldFilter",
                    {
                        "column": "react_item_id",
                        "value": item_id.value
                    }
                ],
                [
                    "FieldFilter",
                    {
                        "column": "state",
                        "value": "Online"
                    }
                ]
            ];

            // Fetch json data
            getDataAsync(`${apiHostname}/${config.value.message.url}`, filters).then(result => {
                data.value = resolveData(result);
            });
        };


        // Set focus on content input
        const setFocus = () => {
            input.value.focus();
        };

        // Handle the submit, for new message
        const handleSubmit = function (e) {
            e.preventDefault();

            const formData = new FormData(e.target);
            formData.set("channel_id", "1");
            formData.set("react_module_object", module_object.value);
            formData.set("react_item_id", item_id.value);

            // Based on extra_comment_workflowevent_id passed, we can run multiple events
            const extra_workflowevent_id = viewData.config.settings?.extra_comment_workflowevent_id;
            actionData.workflowevent_id = extra_workflowevent_id;

            postDataAsync(`${apiHostname}/${config.value.message.url}`, formData).then( result => {
                data.value = [
                    result.data,
                    ...data.value
                ];
                content.value = "";
                setFocus();

                const fullname = value(contact.value, env("variables.contact.properties.fullname") );
                if (fullname) {
                    addHistorylogRecord("Workflowcard", viewData.workflowcard_id,
                        trans("#fullname# heeft een opmerking geplaatst."),
                        " ",
                        {
                            fullname: fullname
                        }
                    );
                } else {
                    addHistorylogRecord("Workflowcard", viewData.workflowcard_id,
                        trans("Iemand heeft een opmerking geplaatst."),
                        " ",
                        {}
                    );
                }
                addNotification(trans("Uw opmerking is geplaatst."), 3500);
                setTimeout(() => {
                    viewData.ticker += 1;
                }, 750);
            });
        };

        onMounted(() => {
            watch(
                () => [ module_object.value, item_id.value ],
                initData,
                {
                    immediate: true
                }
            );
            setFocus();
        });

        const own = (item) => {
            return computed(() => {
                return item.owner == user_id.value;
            }).value;
        };

        const handleActionSuccess = () => {
            actionData.workflowevent_id = null;
        };

        return {
            trans,

            actionData,
            handleActionSuccess,

            input,
            data,
            content,
            isOwn,
            handleSubmit,
            own,
            user_avatar,
            themefolder: config.themefolder
        };
    },
});
</script>