<template>
    <div class="element PriceField" :data-required="this.required" :class="error.error ? 'error' : ''">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <div class="input-group">
            <span class="input-group-text">{{ this.currency }}</span>
            <input
                class="input form-control"
                type="text"
                :id="`field_${this.id}`"
                :name="this.name"
                :readonly="!this.editing || this.readonly"
                :placeholder="this.placeholder"
                :autocomplete="this.autocomplete"
                :maxlength="this.maxlength"
                v-model="input"
                @keypress="isNumber($event)"
                @keyup="formatCurrency($event) && validateInput($event)"
                @blur="formatCurrency($event, 'blur') && validateInput($event)"
            />
        </div>
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>


<script>
import {ref, watch} from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";
import trans from "helpers/_translation";

// Our helpers
import { mergeDeep } from "helpers/_objects";

export default {
    props: mergeDeep(
        propDefaults,
        {
            currency: {
                type: String,
                required: false,
                default: "€"
            }
        }
    ),
    setup(props) {
        let input = ref("");
        const {validateTextField, error} = useFormValidation();
        const validateInput = () => {
            // if (props.required) {
            //     validateTextField(props.label, input.value);
            // }
        };

        const isNumber = (e) => {
            e = e ? e : window.event;
            const charCode = (e.which) ? e.which : e.keyCode;
            // 44 = comma
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
                e.preventDefault();
            } else {
                return true;
            }
        };


        const formatNumber = (n) => {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        };


        const formatCurrency = (e, blur) => {
            // Detect CTRL+A, 17 = Control key, 65 = a
            if (e.keyCode == 17 || e.ctrlKey || e.keyCode == 65 && e.ctrlKey) return;

            // Define our variables
            const input = e.target;
            let value = input.value;

            // don't validate empty input
            if (value === "") return;

            // original length
            const originalLength = value.length;

            // initial caret position
            let newPosition = input.selectionStart;

            // check for decimal
            if (value.indexOf(",") >= 0) {

                // Get position of first decimal, this prevents multiple decimals from being entered
                const decimal_pos = value.indexOf(",");

                // Split number by decimal point
                let left = value.substring(0, decimal_pos);
                let right = value.substring(decimal_pos);

                // Add dots to left side of number
                left = formatNumber(left);
                // Validate right side
                right = formatNumber(right);
                // On blur make sure 2 numbers after decimal
                if (blur === "blur") right += "00";
                // Limit decimal to only 2 digits
                right = right.substring(0, 2);
                // Set value
                value = left + "," + right;

            } else {
                // No decimal entered, so we add dots to our number and remove all non-digits
                value = formatNumber(value);

                // Final formatting
                if (blur === "blur") value += ",00";
            }

            // send updated string to input
            input.value = value;

            // Put caret back in the right position
            newPosition = value.length - originalLength + newPosition;
            input.setSelectionRange(newPosition, newPosition);
        };


        watch(
            () => props.value,
            () => {
                input.value = props.value;
            },
            {
                immediate: true
            }
        );

        return {
            trans,
            input,
            error,
            validateInput,
            isNumber,

            formatCurrency
        };
    },
}
</script>