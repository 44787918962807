import {computed} from "vue";

import { theme } from "config";

// Helpers for the theme, like phonenumer formatting or logo path
// Helpers for the theme, like phonenumer formatting or logo path
// Helpers for the theme, like phonenumer formatting or logo path

export const logo = (name = "logo") => {
	return computed(() => `/images/${theme("subfolder")}/${name}.${theme("logo_extension")}`).value;
};
export const phonenumber = (number) => {
	number = number.replace(/^\+/, '00');
	return number.replace(/\D/g, '');
};