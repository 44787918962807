import { reactive } from "vue";

// Helper functions
import { validateEmail } from "helpers/_validate";

const defaults = {
    error:false,
    message:""
};

export default function useFormValidation(){
    const error = reactive({...defaults});
    const resetError = () => {
        Object.assign(error, defaults);
    };
    const validateEmailField = (fieldName, fieldValue) => {
        if (!validateEmail(fieldValue)) {
            error.error = true;
            error.message = `Vul a.u.b. een geldig emailadres in.`;
        } else {
            resetError();
        }
    };
    const validateTextField = (fieldName, fieldValue) => {
        if (!fieldValue) {
            error.error = true;
            error.message = "Dit veld is verplicht.";
        } else {
            resetError();
        }
    };
    return {
        error,
        validateTextField,
        validateEmailField
    };
}