<template>
    <span class="icon" :name=this.name v-html="icon"></span>
</template>

<style>
span.icon {
    display:inline-block; width:1em; height:1em;
}
span.icon svg { width:1em; height:1em; display:block; fill:currentColor; color:inherit; }
</style>

<script>
import { defineComponent, onMounted, inject, watch, reactive, computed } from "vue";

import { iconData } from "store/icons";

export default defineComponent({
    name: "Icon",
    props: {
        name: {
            type: String,
            required: true
        }
    },
    setup(props) {

        const portal_url = inject("portal_url");
        const componentData = reactive({
            init: false,
            icon: {}
        });

        watch(
            () => props.name,
            (name) => {
                if (!name) return;
                if (!(name in iconData.icons)) {
                    componentData.init = true;
                    iconData.icons[name] = "";

                    let hostname = portal_url.value;
                    if (hostname.startsWith("//dist.saas-workflowportal.localhost.wingzz.nl")) {
                        hostname = "https://portal-macromedics-nl.cloud04.ibizz.nl";
                    }
                    fetch(`${hostname}/icon/${props.name}.svg`)
                        .then(response => {
                            if (!response.ok) {
                                console.log(`Error with icon '${props.name}': ${response.statusText}`);
                                return "";
                            }
                            return response.text();
                        })
                        .then(data => {
                            componentData.icon = iconData.icons[name] = data;
                        })
                        .catch(error => {
                            delete iconData.icons[name];
                            console.error(error);
                        });
                }
                componentData.icon = iconData.icons[name] ?? "";
            },
            {
                immediate: true
            }
        );
        watch(
            () => iconData.icons[props.name],
            () => {
                componentData.icon =  iconData.icons[props.name];
            }
        );

        const icon = computed(() => iconData.icons?.[props.name] ?? "");

        return {
            componentData,
            icon
        }
    },
});
</script>