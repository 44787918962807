<template>
    <div class="element UserField" :data-required="this.required" :class="error.error ? 'error' : ''">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <template v-if="!this.editing || this.readonly">
            <ul class="block-grid one-up cardlist">
                <template v-if="this.value">
                    <li>
                        {{ componentData.users[this.value]?.fullname }}
                    </li>
                </template>
                <template v-else>
                    <li>
                        <small>{{ trans("Geen gebruiker gekoppeld") }}</small>
                    </li>
                </template>
            </ul>
        </template>
        <template v-else>
            <select
                class="input"
                :id="`field_${this.id}`"
                :name="this.name"
                :readonly="!this.editing || this.readonly"
                :autocomplete="this.autocomplete"
            >
                <option value="">{{ trans("- Maak een keuze -") }}</option>
                <template v-for="(item, id) in componentData.users">
                    <option :value="id" :selected="this.value == id">{{ item.fullname }}</option>
                </template>
            </select>
        </template>
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<style>
</style>

<script>
import { ref, watch, reactive, inject } from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";

// Our helpers
import trans from "helpers/_translation";
import { mergeDeep } from "helpers/_objects";

import { portalStore } from "store/portal";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
    },
    setup(props) {
        // Helpers
        const getItems = inject("getItems");

        let input = ref("");
        const {validateTextField, error} = useFormValidation();
        const validateInput = () => {
            if (props.required) {
                validateTextField(props.label, input.value);
            }
        };

        const componentData = reactive({
            users: {}
        });

        watch(
            () => portalStore.user,
            () => {
                componentData.users = portalStore.user ?? {};
            },
            {
                immediate: true
            }
        );

        return {
            trans,
            input,
            error,
            validateInput,
            componentData
        };
    },
}
</script>