import { postDataAsync } from "helpers/_api";
import { error } from "helpers/_error";

// Config for API urls used in this vue template
import { config, apiHostname } from "config";

// Function to add a historylog item
export const addHistorylogRecord = function (module_object, item_id, subject, message = " ", extra_information = []) {
    if (!module_object || !item_id || !subject) {
        error("addHistorylogRecord", "Not all parameters have been passed");
        return;
    }
    extra_information = JSON.stringify(extra_information);
    postDataAsync(`${apiHostname}/${config.value.historylog.url}`, {
        module_object,
        item_id,
        subject,
        message,
        extra_information
    });
};