import { reactive, computed } from "vue";

const SETTING_KEY = "app_settings";
const defaultSettings = {
    layout: {
        mode: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
		prefered: "list"
    }
};
export const appSettings = reactive({
    ...defaultSettings
});

const restoreSettings = () => {
    const savedSettings = window.localStorage.getItem(SETTING_KEY);
    if (savedSettings !== null) {
        Object.assign(appSettings, JSON.parse(savedSettings));
    }
};
restoreSettings();

export const saveSettings = () => {
    window.localStorage.setItem(SETTING_KEY, JSON.stringify(appSettings));
};

const storeSettingsByGroup = (group, formData) => {
    if (!group) return;
    Object.keys(appSettings[group]).forEach(key => {
        if (Array.isArray(appSettings[group][key])) {
            appSettings[group][key] = formData.getAll(`${key}[]`);
        } else {
            appSettings[group][key] = formData.get(key);
        }
    });
};
export const storeSettings = (group, formData) => {
    if (group) {
        storeSettingsByGroup(group, formData);
    } else {
        Object.keys(appSettings).forEach(group => {
            storeSettingsByGroup(group, formData);
        });
    }
    saveSettings();
};
export const storeSetting = (group, key, value) => {
    const formData = new FormData();
    formData.set(key, value);
    storeSettings(group, formData);
};
export const resetSettings = (group) => {
    if (group) {
        appSettings[group] = {...defaultSettings[group]};
    } else {
        Object.assign(appSettings, {...defaultSettings});
    }
    window.localStorage.removeItem(SETTING_KEY);
};
export const setSetting = (group, key, value) => {
    // NOT NEEDED, WE COULD DO INSTANT SAVING WITH storeSetting(s) for example
    // NOT NEEDED, WE COULD DO INSTANT SAVING WITH storeSetting(s) for example
    // NOT NEEDED, WE COULD DO INSTANT SAVING WITH storeSetting(s) for example
    if (!appSettings[group]?.[key]) return;
    appSettings[group][key] = value;
};
export const getSetting = (group, key) => {
    return computed(() => {
        return appSettings[group]?.[key];
    }).value;
};
export const matchSetting = (group, key, value) => {
    if (!value) return false;
    const storedValue = getSetting(group, key);
    if (Array.isArray(storedValue)) {
        return storedValue.includes(`${value}`);
    }
    return storedValue === value;
};