<template>
    <div ref="fieldRef" class="element CardField" :data-required="this.required" :class="error.error ? 'error' : ''">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <template v-if="!this.editing || this.readonly">
            <ul class="block-grid one-up cardlist">
                <template v-if="componentData.workflowcard_id">
                    <li>
                        <Cardlink :key="componentData.workflowcards[componentData.workflowcard_id]" :id="componentData.workflowcard_id" :data="componentData.workflowcards[componentData.workflowcard_id]" />
                    </li>
                </template>
                <template v-else>
                    <li>
                        <small>{{ trans("Geen '%s' gekoppeld", this.label) }}</small>
                    </li>
                </template>
            </ul>
        </template>
        <template v-else>
            <select
                class="input"
                :id="`field_${this.id}`"
                :name="this.name"
                :readonly="!this.editing || this.readonly"
                :autocomplete="this.autocomplete"
            >
                <option value="">{{ trans("- Maak een keuze -") }}</option>
                <template v-for="item in order(conditional(componentData.workflowcards))">
                    <option :value="item.id" :selected="item.id == componentData.workflowcard_id">{{ item.resolved_title }}</option>
                </template>
            </select>
        </template>
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<style>
    .CardField .cardlist a {
        text-decoration:underline;
        &:hover {
            text-decoration:none;
         }
    }
</style>

<script>
import { ref, watch, reactive, inject, computed, onMounted } from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";
import trans from "helpers/_translation";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import { resolveData, formatData } from "helpers/_data";
import { getDataAsync } from "helpers/_api";

import { resolveTitle } from "store/workflow";

// Components
import Cardlink from "components/workflowcard/cardlink";

// Config for API urls used in this vue template
import { env, config, apiHostname } from "config";

import { company, item, fetchItem } from "store/portal";

export default {
    props: mergeDeep(
        propDefaults,
        {
            values: {
                type: Object,
                required: false,
                default: () => ({})
            }
        }
    ),
    components: {
        Cardlink
    },
    setup(props) {

        // Helpers
        const fieldRef = ref();
        const generalData = inject("generalData");

        let input = ref("");
        const {validateTextField, error} = useFormValidation();
        const validateInput = () => {
            if (props.required) {
                validateTextField(props.label, input.value);
            }
        };

        const componentData = reactive({
            workflowcard_id: null,
            workflowcards: {},
            conditions: {}
        });

        const resolvePortalFilters = (filters) => {
            return filters.map(filter => {
                try {
                    const filterObject = JSON.parse(filter);
                    if (filterObject.c === env("company_property_name")) {
                        filterObject.v = company.value?.data?.id;
                        filterObject.o = "=";
                    }
                    return JSON.stringify(filterObject);
                } catch (e) {
                    return filter;
                }
            });
        };

        const getComponentData = (url) => {
            return new Promise(resolve => {

                const optionsJson = JSON.parse(props.options_json) ?? {
                    filters: []
                };
                const optionsJsonFilters = optionsJson.filters;
                const optionFilters = optionsJsonFilters.map(x => JSON.stringify(x));
                getDataAsync(url ? url : `${apiHostname}/${config.value.workflowcard.url}`, url ? [] : resolvePortalFilters(optionFilters)).then(json => {

                    const newData = formatData(json).data;
                    Object.values(newData).forEach(item => {
                        item.resolved_title = resolveTitle(item);
                    });

                    componentData.workflowcards = {...componentData.workflowcards, ...newData};
                    if (json._links?.next?.href) {
                        getComponentData(json._links.next.href).then(resolve);
                    } else {
                        resolve();
                    }
                });
            });
        };
        const initComponentData = () => {
            const id = props.value;
            componentData.workflowcards = {};
            componentData.workflowcard_id = props.value;

            // The filters we use to get our cards
            getComponentData().then(
                () => {
                    // Check if the workflowcard used is in the list of cards stored
                    if (id && !(id in componentData.workflowcards)) {
                        fetchItem("workflowcard", id).then((result) => {
                            result.resolved_title = resolveTitle(result);

                            componentData.workflowcards = mergeDeep(
                                componentData.workflowcards,
                                {
                                    [result.id]: result
                                }
                            );
                        });
                    }
                }
            );
        };

        watch(
            () => props.value,
            initComponentData,
            {
                immediate: true
            }
        );

        const order = (data) => {
            return computed(() => {
                // Sort alphabetically
                return Object.values(data).sort((a, b) => {
                    return a.resolved_title.replace(/\s/g, "").localeCompare(b.resolved_title.replace(/\s/g, ""));
                });
            }).value;
        };


        const conditional = (data) => {
            return computed(() => {
                return Object.values(data).filter(item => {
                    return Object.keys(componentData.conditions).every(condition => {
                        const target_property = item?.extra?.workflowproperties?.find(a => a?.field?.name === condition);
                        if (target_property) {
                            return `${target_property.value}` === `${componentData.conditions[condition]}`;
                        }
                        return true;
                    });
                });
            }).value;
        };

        const storeCondition = (name, value) => {
            componentData.conditions = {
                ...componentData.conditions,
                [name]: value
            };
        };

        onMounted(() => {
            if (Object.keys(env("custom_property_condition_usage_map")).includes(props.name)) {
                // Current property values should change based on any property change
                const condition_property_targets = env("custom_property_condition_usage_map")[props.name];
                const form = fieldRef.value.closest(".cs_form");
                if (form) {
                    form.addEventListener("change", () => {
                        const formData = new FormData(form);
                        condition_property_targets.forEach(condition_property_target => {
                            formData.has(condition_property_target.input) && storeCondition(condition_property_target.filter, formData.get(condition_property_target.input));
                        });
                    });
                    condition_property_targets.forEach(condition_property_target => {
                        const tmpObject = Object.values(props.values.value).find(a => a?.field?.name === condition_property_target.input);
                        if (tmpObject) {
                            storeCondition(condition_property_target.filter, tmpObject.value);
                        }
                    });
                }
            }
        });

        return {
            trans,
            input,
            error,
            validateInput,
            componentData,

            resolveTitle,

            order,
            conditional,

            fieldRef
        };
    },
}
</script>