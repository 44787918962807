// Normal fields
import AddressField from './AddressField';
import TextField from './TextField';
import TextareaField from './TextareaField';
import EmailField from './EmailField';
import HiddenField from './HiddenField';
import NumberField from './NumberField';
import PriceField from './PriceField';
import PercentageField from './PercentageField';
import PhoneField from './PhoneField';

// Visual fields
import TitleField from './TitleField';
import CardField from './CardField';
import UserField from './UserField';
import UploadField from './UploadField';
import ImageField from './ImageField';
import ImageUploadField from './ImageUploadField';
import FileUploadField from './FileUploadField';

// Component fields
import DateField from './DateField';
import DatetimeField from './DatetimeField';
import EditorField from './EditorField';
import TimeField from './TimeField';
import ColorField from './ColorField'; // For now just simple input[type="color"]
import JsonField from './JsonField';

// With predefined options
import RadioField from './RadioField';
import CheckboxField from './CheckboxField';
import SelectField from './SelectField';

export default {
    // Normal fields
    "NoticeField": TextField,
    "AddressField" : AddressField,
    "TextField" : TextField,
    "TextareaField" : TextareaField,
    "EmailField" : EmailField,
    "HiddenField" : HiddenField,
    "NumberField" : NumberField,
    "PriceField" : PriceField,
    "EuroField" : PriceField,
    "PercentageField" : PercentageField,
    "PhoneField" : PhoneField,
    // Visual fields
    "TitleField" : TitleField,
    "CardField" : CardField,
    "UserField" : UserField,
    "UploadField": UploadField,
	"ImageField": ImageField,
	"ImageUploadField": ImageUploadField,
	"FileUploadField": FileUploadField,
    // Component fields
    "DateField" : DateField,
    "DatetimeField" : DatetimeField,
    "EditorField" : EditorField,
    "TimeField" : TimeField,
    "ColorField" : ColorField,
	"JsonField" : JsonField,
    // With predefined options
    "RadioField" : RadioField,
    "CheckboxField" : CheckboxField,
    "SelectField" : SelectField
};