// Define our cached values
import {watch, computed } from "vue";

const CACHE_PREFIX = "app";

const cacheObject = {};
export const initCache = (parent, key, target) => {
	if (!cacheObject[parent]) cacheObject[parent] = {};
	cacheObject[parent][key] = {
		target: target,
		data: null
	};

	const storage_id = `${CACHE_PREFIX}_${parent}_${key}`;
	cacheObject[parent][key] = JSON.parse(atob(window.localStorage.getItem(storage_id) ?? "") || null);
	target[key] = cacheObject[parent][key];

	watch(
		() => target[key],
		(tmpData) => {
			if (!tmpData) return;
			window.localStorage.setItem(storage_id, btoa(JSON.stringify(tmpData)));
		}
	);
};
export const cache = (parent, key) => {
	return computed(() => {
		if (!cacheObject[parent] || !cacheObject[parent][key]) return null;
		return cacheObject[parent][key];
	}).value;
};

export const clearCache = () => {
	Object.keys(cacheObject).forEach((parent) => {
		Object.keys(cacheObject[parent]).forEach((key) => {
			window.localStorage.removeItem(`${CACHE_PREFIX}_${parent}_${key}`);
		});
	});

	// Clear all cache
	localStorage.clear();
};