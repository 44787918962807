<template>
    <a v-if="componentData.workflowcard" @click="handleClick">
        <card-title v-if="componentData.workflowcard" :data="componentData.workflowcard" />
    </a>
</template>

<script>
import {defineComponent, onMounted, computed, inject, reactive, watch, ref} from "vue";
import { useRouter } from "vue-router";

import cardTitle from "components/workflowcard/cardtitle";

import { item, fetchItem } from "store/portal";

export default defineComponent({
    name: "Cardlink",
    props: {
        id: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: false
        }
    },
    components: {
        cardTitle
    },
    setup(props) {
        const router = useRouter();

        const componentData = reactive({
            workflowcard: null
        });

        const onInit = () => {
            componentData.workflowcard = null;
            if (props.data) {
                // Situation where card is just passed
                componentData.workflowcard = props.data;
            } else {
                if (!props.id) return;
                fetchItem("workflowcard", props.id);
                componentData.workflowcard = computed(() => item("workflowcard", props.id));
            }
        };
        onMounted(onInit);
        watch(
            () => props.id,
            onInit
        );

        const handleClick = () => {
            router.push(`/workflowboard/${componentData.workflowcard.extra.workflowboard.id}/workflowcard/${componentData.workflowcard.data.id}`);
        };

        return {
            componentData,
            handleClick
        }
    }
});
</script>