import {computed, reactive, watch} from "vue";

import { item, fetchItem} from "store/portal";
import { getDataWithoutLimit } from "helpers/_api";

import { config, apiHostname } from "config";

export const workflowStore = reactive({
	workflowproperty: {},
	workflowpropertyfieldsbygroup: {},
	workflowpropertyfieldgroupsbygroupid: {},
	workflowcardtitlesbyboardidandcardid: {},
	workflowlayoutcardoverview: [],
	workflowlayoutcardoverviewbyboardid: {},
	workflowphasesbyboardid: {},
	workfloweventsbyphaseid: {},
	workfloweventactionbyeventid: {},
	subcard_watchers: {}
});

export const workflowlayout = (workflowboard_id, layout = "overview") => {
	return computed(() => {
		if (!workflowboard_id) return [];
		const workflowboard = item("workflowboard", workflowboard_id);
		return workflowboard?.extra?.workflowlayout[layout];
	}).value;
};
export const workflowlayoutdetailtab = (workflowboard_id) => {
	return computed(() => {
		if (!workflowboard_id) return [];
		const workflowboard = item("workflowboard", workflowboard_id);
		return workflowboard?.extra?.workflowlayoutdetailtab;
	}).value;
};



export const workflowcardtype = (workflowcardtype_id) => {
	return computed(() => {
		if (!workflowcardtype_id) return [];
		fetchItem("workflowcardtype", workflowcardtype_id);
		return item("workflowcardtype", workflowcardtype_id);
	}).value;
};

const getWorkflowlayoutCardoverviewByBoardId = (workflowboard_id) => {
	if (workflowboard_id in workflowStore.workflowlayoutcardoverviewbyboardid) return;
	workflowStore.workflowlayoutcardoverviewbyboardid[workflowboard_id] = null;
	const tmpObject = {
		data: []
	};
	const filters = [
		[

			"FieldFilter",
			{
				"column": "state",
				"value": "Online"
			}
		],

		[
			"RelatedFilter",
			{
				"linked_ids": [workflowboard_id],
				"match_all": false,
				"module": "Workflowboard",
				"module_link": "Workflowlayoutcardoverview"
			}
		]
	];
	getDataWithoutLimit(`${apiHostname}/${config.value.workflowlayoutcardoverview.url}`, filters, tmpObject, "data").then(result => {
		workflowStore.workflowlayoutcardoverviewbyboardid[workflowboard_id] = tmpObject.data;
	});
};
export const workflowlayoutcardoverview = (workflowboard_id) => {
	return computed(() => {
		if (!workflowboard_id) return true;
		getWorkflowlayoutCardoverviewByBoardId(workflowboard_id);
		return workflowStore.workflowlayoutcardoverviewbyboardid[workflowboard_id];
	}).value;
};


const getWorkflowphasesByBoardId = (workflowboard_id) => {
	if (workflowboard_id in workflowStore.workflowphasesbyboardid) return;
	workflowStore.workflowphasesbyboardid[workflowboard_id] = {};
	const tmpObject = {
		data: []
	};
	const filters = [
		[

			"FieldFilter",
			{
				"column": "state",
				"value": "Online"
			}
		],

		[
			"FieldFilter",
			{
				"column": "workflowboard_id",
				"value": workflowboard_id
			}
		]
	];
	getDataWithoutLimit(`${apiHostname}/${config.value.workflowphase.url}`, filters, tmpObject, "data").then(result => {
		Object.assign(workflowStore.workflowphasesbyboardid[workflowboard_id], tmpObject.data);
	});
};
export const workflowphase = (workflowboard_id) => {
	return computed(() => {
		if (!workflowboard_id) return [];
		getWorkflowphasesByBoardId(workflowboard_id);
		return workflowStore.workflowphasesbyboardid[workflowboard_id];
	}).value;
};

export const workflowproperties = (workflowcard_id) => {
	return computed(() => {
		if (!workflowcard_id) return [];
		const workflowcard = item("workflowcard", workflowcard_id);
		const data = {};
		if (workflowcard?.extra?.workflowproperties) {
			Array.from(workflowcard?.extra?.workflowproperties).forEach(a => {
				data[a.field.id] = a;
			});
		}
		return data;
	});
};

export const workflowpropertyByName = (name) => {
	return computed(() => {
		// These are stored by workflowpropertiesbyfieldgroup
		return workflowStore.workflowproperty[name];
	}).value;
};

export const workflowpropertiesbyfieldgroup = (workflowfieldgroup_id) => {
	if (!(workflowfieldgroup_id in workflowStore.workflowpropertyfieldsbygroup)) {
		workflowStore.workflowpropertyfieldsbygroup[workflowfieldgroup_id] = [];
		const tmpObject = {data: []};
		getDataWithoutLimit(`${apiHostname}/api/v1.0/workflowpropertyfield`, [
			[
				"FieldFilter",
				{
					"c": "workflowpropertyfieldgroup_id",
					"v": workflowfieldgroup_id
				}
			],
			[
				"FieldFilter",
				{
					"c": "state",
					"v": "Online"
				}
			]
		], tmpObject, "data").then(() => {
			workflowStore.workflowpropertyfieldsbygroup[workflowfieldgroup_id] = tmpObject.data;
			tmpObject.data.forEach(workflowproperty => {
				if (workflowproperty.id in workflowStore.workflowproperty) return;
				// Store data for later use
				workflowStore.workflowproperty[workflowproperty.name] = workflowproperty;
			});
		});
	}
	return computed(() => {
		if (!workflowfieldgroup_id) return [];
		return workflowStore.workflowpropertyfieldsbygroup[workflowfieldgroup_id];
	}).value;
};
export const workflowpropertyfieldgroupsbyfieldgroupid = (workflowfieldgroup_id) => {
	if (!(workflowfieldgroup_id in workflowStore.workflowpropertyfieldgroupsbygroupid)) {
		workflowStore.workflowpropertyfieldgroupsbygroupid[workflowfieldgroup_id] = [];
		const filters = [
			[
				"FieldFilter",
				{
					"c": "workflowpropertyfieldgroup_id",
					"v": workflowfieldgroup_id
				}
			]
		];
		const tmpObject = {data: []};
		getDataWithoutLimit(`${apiHostname}/api/v1.0/workflowpropertyfieldgroup`, filters, tmpObject, "data").then(() => {
			workflowStore.workflowpropertyfieldgroupsbygroupid[workflowfieldgroup_id] = tmpObject.data;
		});
	}
	return computed(() => {
		if (!workflowfieldgroup_id) return [];
		return workflowStore.workflowpropertyfieldgroupsbygroupid[workflowfieldgroup_id];
	}).value;
};

export const resolvePropertyType = (property) => {
	return property.field?.type.split("\\").pop();
};
export const resolveProperty = (item, propertyfield_id) => {
	return computed(() => {
		return item.extra?.workflowproperties.find(a => {
			return `${a.field.id}` === `${propertyfield_id}`;
		});
	}).value;
};


export const resolveTitle = (data, board_id, view_properties = {}) => {
	board_id = board_id ? `${board_id}` : `${data.extra?.workflowboard.id}`;
	if (typeof board_id !== "undefined") {
		fetchItem("workflowboard", board_id);
	}
	const tmpId = `${data?.data?.id || data.id}`;
	if (!tmpId) return;
	if (workflowStore.workflowcardtitlesbyboardidandcardid[board_id]?.[tmpId]) {
		return workflowStore.workflowcardtitlesbyboardidandcardid[board_id][tmpId];
	}

	let title = "";
	const tmpWorkflowlayout = workflowlayout(board_id, "title");

	if (tmpWorkflowlayout) {
		tmpWorkflowlayout.map(a => a.property.id).forEach(property_id => {
			const originalProperty = data.extra?.workflowproperties.find(a => `${a.field.id}` == `${property_id}`);
			let property = originalProperty;
			if (property_id in view_properties) {
				property = view_properties[property_id];
			}
			if (!property || !originalProperty) return;
			if (resolvePropertyType(originalProperty) === "CardField" && property.value) {
				const tmpItem = item("workflowcard", property.value);
				if (tmpItem) {
					title += resolveTitle(tmpItem);
				} else if (!(property.value in workflowStore.subcard_watchers)) {
					fetchItem("workflowcard", property.value);

					workflowStore.subcard_watchers[`${property.value}`] = watch(
						() => tmpItem,
						(result) => {
							if (result && result.id) setTitle();
						},
						{
							immediate: true
						}
					);
				}
			} else {
				title += `${property.value} `;
			}
		});
	}
	if (!(board_id in workflowStore.workflowcardtitlesbyboardidandcardid)) {
		workflowStore.workflowcardtitlesbyboardidandcardid[board_id] = {};
	}
	workflowStore.workflowcardtitlesbyboardidandcardid[board_id][tmpId] = title || item.title;
	return title || data.title;
};