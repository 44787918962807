<template>
    <div class="element" :data-required="required" :class="{ [this.type] : true, error : error.error, success: !error.error && error.init }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <placeholderElement v-bind="resolveProps('placeholder', this)" :error="error" :value="this.input" />
        <stateElement v-bind="resolveProps('state', this)" :error="error" />
        <input
            type="tel"
            class="input"
            :id="`field_${this.id}`"
            :name="this.name"
            :readonly="!this.editing || this.readonly"
            :placeholder="`${this.show_placeholder ? placeholder + (required ? ' *' : '') : ''}`"
            :autocomplete="this.autocomplete"
            :maxlength="this.maxlength"
            v-model="input"
            @keyup="error.init && validate($event)"
            @blur="validate"
            @change="handleChange"
        />
        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {defineComponent,ref, watch, computed} from "vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

export default defineComponent({
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {
        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();

        watch(
            () => props.value,
            () => {
                input.value = props.value;
            },
            {
                immediate: true
            }
        );

        const required = computed(() => props.required);
        watch(
            () => props.required,
            () => {
                registerValidator(input, props);
            },
            {
                immediate: true
            }
        );

        // If we got a initial value, validate instantly
        props.value && validate();

        const handleChange = () => {
            emit('value-change', {
                name: props.name,
                value: input.value
            });
        }

        const placeholder = computed(() => props.translate ? trans(props.placeholder) : props.placeholder);

        return {
            trans,
            input,
            error,
            resolveProps,
            validate,
            required,
            handleChange,
            placeholder
        };
    },
});
</script>