<template>
    <template v-if="componentData.workflowcardtype">
        <workflowphase-overview
            v-if="componentData.workflowcardtype.data.workflowboard_id"
            :simple="true"
            :workflowboard_id="componentData.workflowcardtype.data.workflowboard_id"
            :related_filter_json="componentData.related_filter_json"
        ></workflowphase-overview>
    </template>
</template>

<script>

import {defineComponent, onMounted, onUnmounted, reactive, inject, watch, computed } from "vue";

// Components
import workflowphaseOverview from "view/dashboard/includes/workflowphase-overview";

// Helpers
import trans from "helpers/_translation";
import {workflowcardtype} from "store/workflow";

export default defineComponent({
    name: "workflowlayoutcard-overview",
    components: {
        workflowphaseOverview
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup(props) {

        const viewData = inject("viewData");

        const componentData = reactive({
            workflowcardtype: computed(() => {
                if (!props.data.workflowcardtype_id) return null;
                return workflowcardtype(props.data.workflowcardtype_id);
            }),
            related_filter_json: computed(() => props.data.filter_json)
        });

        return {
            trans,

            viewData,
            componentData
        };
    },
});

</script>