<template>
    <section class="Block Upload Overview list">
        <div class="inner">
            <div v-for="item in componentData.data" class="Block Upload Item list colored bgwhite linkdiv useHand" @click="handleClick(item)">
                <div class="td-filetype">
                    <div class="filetype" :class="resolveSimplifiedFiletype(item.filetype)">
                        <Icon :name="`filetype--${resolveSimplifiedFiletype(item.filetype)}`" />
                    </div>
                </div>
                <div class="td-title">
                    <h3 class="h3">{{ item.title }}</h3>
                </div>
                <div class="td-icons">
                    <Icon name="view" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {defineComponent, onMounted, reactive, ref, watch } from "vue";

// Helper functions
import { postDataAsync, getDataAsync } from "helpers/_api";
import { resolveData } from "helpers/_data";

// External components

// Components
import Icon from "components/icon.vue";

// Config for API urls used in this vue template
import { config, apiHostname } from "config";

export default defineComponent({
    name: "file-overview",
    components: {
        Icon
    },
    props: {
        item_id: {
            type: String,
            required: false
        },
        module_object: {
            type: String,
            required: false
        },
        updatekey: {
            type: Number,
            required: false,
            default: 1
        }
    },
    setup(props) {

        const componentData = reactive({
            "data": []
        });

        const initData = () => {
            getDataAsync(`${apiHostname}${config.value.upload.url}`, [
                [
                    "RelatedFilter",
                    {
                        "linked_ids": [props.item_id],
                        "match_all": false,
                        "module": props.module_object,
                        "module_link": "Upload"
                    }
                ],
                [
                    "FieldFilter",
                    {
                        "column": "state",
                        "value": "Online"
                    }
                ]
            ]).then(result => {
                componentData.data = resolveData(result);
            });
        };

        watch(
            () => props.updatekey,
            initData,
            {
                immediate:true
            }
        );

        const resolveSimplifiedFiletype = (filetype) => {

            const fileMap = {
                "pdf" : "pdf",
                "vnd.openxmlformats-officedocument.wordprocessingml.document" : "word",
                "plain" : "word",
                "doc" : "word",
                "docx" : "word",
                "dotx" : "word",
                "xls" : "excel",
                "xlsx" : "excel",
                "ppt" : "powerpoint",
                "pps" : "powerpoint",
                "ppsx" : "powerpoint",
                "pptx" : "powerpoint",
                "sql" : "sql",
                "zip" : "zip",
                "jpeg" : "jpg",
                "mp4" : "video",
                "mov" : "video",
                "avi" : "video"
            };

            // Check if we have an icon for a specific file type
            if (filetype in fileMap) {
                return fileMap[filetype];
            }

            return filetype ?? "word";
        };

        const handleClick = (item) => {
            if (!item._meta.url) {
                console.log("_meta url wasn't found.");
                return;
            }
            window.open(item._meta.url, '_blank').focus();
        };

        return {
            componentData,
            resolveSimplifiedFiletype,
            handleClick
        };
    },
});
</script>

<style>
</style>