
<template>
    <div class="profilePart">
<!--        {{ role }}-->
        <div class="myProfile">
            <div class="left">
                <div class="avatar">
                    <img v-if="user_avatar()" :src="user_avatar()" alt="" />
                    <icon v-else name="profile" />
                </div>
            </div>
            <div class="right">
                <span class="name">{{ value(contact, env("variables.contact.properties.fullname") ) }}</span>
                <div class="details">
                    <span class="role"><icon name="profile" />{{ value(contact, env("variables.contact.properties.role") ) }}</span>
                    <span class="email"><icon name="mail" />{{ value(contact, env("variables.contact.properties.email") ) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

import Icon from "components/icon";
import trans from "helpers/_translation";
import { contact, value } from "store/portal";
import { env } from "config";

import { role } from "store/portal";
import {user_avatar} from "store/portal";

export default defineComponent({
    name: "part",
    components: {
        Icon
    },
    setup() {
        return {
            env,
            value,
            contact,

            trans,
            role,
            user_avatar
        }
    }
});
</script>
