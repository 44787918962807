import {reactive, computed} from "vue";

import { resolveProperty } from "store/workflow";

const sortingDefaults = {
	column: "default",
	order: "asc"
};
export const sortingData = reactive({
	...sortingDefaults
});
export const resetSortingData = () => {
	Object.assign(sortingData, {...sortingDefaults});
};

export const getSortingColumn = () => {
	return sortingData.column;
};
export const getSortingOrder = () => {
	return sortingData.order;
};
export const setSortingColumn = (column) => {
	if (sortingData.column !== column) {
		sortingData.column = column;
		sortingData.order = "asc";

	} else {
		sortingData.order = sortingData.order === "asc" ? "desc" : "asc";
	}
};
export const isSortingColumn = (column) => {
	return sortingData.column === column;
};
export const isSortingOrder = (order) => {
	return sortingData.order === order;
};
export const sort = (data) => {
	return computed(() => {
		const isProperty = !isNaN(sortingData.column);
		data.sort((a, b) => {
			let compareA = a[sortingData.column] || "";
			let compareB = b[sortingData.column] || "";
			if (isProperty) {
				compareA = resolveProperty(a, sortingData.column)?.value || "";
				compareB = resolveProperty(b, sortingData.column)?.value || "";
			}
			// Sorts alphabetically
			Array.isArray(compareA) && (compareA = compareA.join(", "));
			Array.isArray(compareB) && (compareB = compareB.join(", "));
			return compareA.localeCompare(compareB);
		});
		if (sortingData.order === "desc") {
			data = data.reverse();
		}
		return data;
	}).value;
};