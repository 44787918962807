<template>
    <div class="App" :class="{
        [componentData.name] : true,
        [getSetting('layout', 'mode')] : isWorkflowportal ? true : false,
        [application]: true
        }">
        <div class="left ">
            <headerElement @toggleMenu="toggleOpenMenu" />
            <navigationElement :class="{ visible: isLeftVisible }" />
        </div>
        <div class="right">
            <div class="top">
                <router-view />
            </div>
            <footerElement v-if="isWorkflowportal" />
        </div>
    </div>
</template>

<script>
import {defineComponent, reactive, ref, watch, onMounted, inject, computed} from "vue";
import { useRoute } from "vue-router";

import headerElement from "layout/header";
import navigationElement from "layout/navigation";
import footerElement from "layout/footer";
import { getSetting } from 'store/settings';

import trans from "helpers/_translation";
import Icon from "components/icon";
import Popup from "components/popup";
import { config, env, apiHostname } from "config";
import { getData, getDataAsync, getDataWithoutLimit } from "helpers/_api";
import Pagination from "js/components/pagination.vue";
import {hasQuery, getQuery, setQuery, getUrlWithQueryItemReplaced} from "helpers/_structure";


export default defineComponent({
    name: "Layout",
    computed: {},
    components: {
        headerElement,
        navigationElement,
        footerElement,
        Popup
    },
    setup() {
        const application = inject("application");
        const isWorkflowportal = inject("isWorkflowportal");

        // Our defaults
        const route = useRoute();
        const isLeftVisible = ref(false);

        // Define our parameters
        const componentData = reactive({
            name: route.name,
            items: []
        });

        const toggleOpenMenu = () => {
            isLeftVisible.value = !isLeftVisible.value;
        };

        const handleDocumentClick = (event) => {
            const leftElement = document.querySelector('.left');
            if (leftElement && !leftElement.contains(event.target)) {
                isLeftVisible.value = false;
            }
        };

        watch(
            () => route.name,
            (name) => {
                componentData.name = name;
            }
        );

        onMounted(() => {
            document.addEventListener('click', handleDocumentClick);
        });

        return {
            trans,
            application,
            isWorkflowportal,

            componentData,
            isLeftVisible,
            toggleOpenMenu,
            getSetting
        };
    },
});
</script>
