// Helper functions
import { patchDataAsync, postDataAsync } from "helpers/_api";

// Config for API urls used in this vue template
import { config, apiHostname } from "config";

export const setWorkflowpropertyfield = (workflowcardData, card_id, field, value) => {

    // Set is_json Y based on if value is array and has multiple instances + stringify the value
    let is_json = "N";
    if (Array.isArray(value)) {
        if (value.length > 1) {
            value = JSON.stringify(value);
            is_json = "Y";
        } else {
            value = value[0];
        }
    }

	const targetProperty = workflowcardData.extra.workflowproperties.find(a => a.field.id == field.id);
    if (targetProperty) {
        // If value is the same just return false, we don't need to change
        if (targetProperty.value == value) return false;

        // Store our value
        patchDataAsync(`${apiHostname}/${config.value.workflowproperty.url}/${targetProperty.id}`, { value: value, is_json: is_json }).then(result => {
            // Now we store our result in our workflowcardData again
            Object.assign(targetProperty, result.data);
        });
        return true; // Defines if we made changes
    } else if (value) {
        // We have value and it didn't yet exist, we should post
        postDataAsync(`${apiHostname}/${config.value.workflowproperty.url}`, {
            "value": value,
            "workflowcard_id": card_id,
            "workflowpropertyfield_id": field.id,
            "is_json": is_json
        }).then(result => {
            // Now we store our result in our workflowcardData again
			workflowcardData.extra.workflowproperties.push({
				id: result.data.id,
				value: result.data.value,
				field: {
					...field
				}
			});
        });

        return true; // Defines if we made changes
    }
    // Return false if no changes made
    return false;
};