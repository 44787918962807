import {provide, reactive, watch} from "vue";

const ICON_KEY = "portal_icons";

export const iconData = reactive({
	icons: JSON.parse(window.localStorage.getItem(ICON_KEY)) ?? {}
});
watch(
	() => iconData.icons,
	(icons) => {
		if (!Object.keys(icons).length) return;
		window.localStorage.setItem(ICON_KEY, JSON.stringify(iconData.icons));
	},
	{
		immediate: true,
		deep: true
	}
);