<template>
    <div class="element DateField" :data-required="this.required" :class="error.error ? 'error' : ''">
        <label v-if="this.show_label" :for="`field_${this.id}`">{{ this.label }}</label>

        <template v-if="!this.editing || this.readonly">
            <input
                class="input"
                type="text"
                v-model="input"
                :readonly="!this.editing || this.readonly"
            />
        </template>
        <template v-else>
            <input
                type="hidden"
                v-model="input"
                :name="this.name"
            />
            <Calendar
                v-if="!(!this.editing || this.readonly)"
                :id="`field_${this.id}`"
                v-model="calendarInput"
                inputClass="input"
                dateFormat="yy-mm-dd"
                :touchUI="false"
                :showTime="false"
                :timeOnly="false"
                :disabledDates="[]"
            />
        </template>

        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<script>
import {ref, watch, onMounted} from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";

// Our external components
import Calendar from 'primevue/calendar';
import trans from "helpers/_translation";
import moment from "moment";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        Calendar
    },
    setup(props, { emit }) {
        let input = ref("");
        const calendarInput = ref();

        const {validateTextField, error} = useFormValidation();
        const validateInput = () => {
            if (props.required) {
                validateTextField(props.label, input.value);
            }
        };

        watch(
            () => props.value,
            (val) => {
                input.value = val;

                if (!props.value) return;
                const date = new Date(props.value);
                calendarInput.value = date;
            },
            {
                immediate: true
            }
        );

        watch(
            () => calendarInput.value,
            (val) => {
                input.value = moment(val).format("YYYY-MM-DD");

				emit('value-change', {
					name: props.name,
					value: val
				});
            }
        );

        return {input, calendarInput, error, validateInput, trans};
    },
}
</script>

<style>
    .p-datepicker {
        background: #fff;
        box-shadow: 3px 3px 10px rgba(#000,0.3);
        padding: 10px;

        button {
            border:0; background:none transparent; font-weight:bold; color:inherit;
        }

        .p-datepicker-calendar th {
            text-align:center; text-transform:lowercase;
            padding:5px 0;
        }

        .p-disabled {
            opacity:0.5;
            pointer-events:none;
        }
        .p-datepicker-decade {
            font-weight:bold;
        }
        .p-yearpicker-year, .p-monthpicker-month, .p-datepicker-calendar td > span {
            padding:5px;
        }
        .p-link {
            padding:10px;
        }
        .p-highlight {
            font-weight:bold;
        }
    }
</style>