<template>
    <div class="Block Dashboard Login">
        <div class="inner">
            <div class="Heading">
                <span class="left">
                    <Logo />
                </span>
                <span class="right">
                    <h1 class="h1">{{ trans(componentData.type === "forget" ? 'Wachtwoord vergeten' : 'Inloggen') }}</h1>
                </span>
            </div>
            <form ref="formRef" class="cs_form" @submit.prevent="handleSubmit">
                <Fields
                    v-if="componentData.form"
                    :form="form"
                    :fields="componentData.form"
                    :values="{}"
                    :show_label=false
                    :show_placeholder=true
                    :show_error_message=true
                    :translate="true"
                />
                <MessageOverview ref="messageRef" />
<!--                <LanguageSelect />-->
                <nav>
                    <button v-if="['forget'].includes(componentData.type)" type="submit" class="more">
                        <span class="title">{{ trans('Wachtwoord aanvragen') }}</span>
                    </button>
                    <button v-if="['login'].includes(componentData.type)" type="submit" class="more">
                        <span class="title">{{ trans('Inloggen') }}</span>
                    </button>
                </nav>
                <nav>
                    <button v-if="['login'].includes(componentData.type)" type="button" class="more-inline" @click="setType('forget')">
                        <span class="title">{{ trans('Wachtwoord vergeten') }}</span>
                    </button>
                    <button v-if="['forget'].includes(componentData.type)" type="button" class="more-inline" @click="setType('login')">
                        <span class="title">{{ trans('Terug naar login') }}</span>
                    </button>
                </nav>
            </form>
        </div>
    </div>
</template>

<script>
import {defineComponent, ref, reactive, watch, computed, inject } from "vue";

import { useRouter } from "vue-router";

import { apiHostname } from "config";

import { fetchToken } from "services/JwtService";

import Fields from "formbuilder/fields.vue";
import { useFormValidation } from "formbuilder/fields/_validation";

import { isPortal } from "config/router";
import { getAuthenticationForm } from "config/forms";

import Icon from "components/icon.vue";

import MessageOverview from "elements/message.vue";
import ButtonText from "elements/button-text";

// Our helpers
import trans from "helpers/_translation";
import { postData } from "helpers/_api";

import Logo from "elements/logo";
import LanguageSelect from "elements/language-select";

export default defineComponent({
    name: "dashboardLogin",
    props: {
        boxed: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        Logo,
        Fields,
        Icon,
        MessageOverview,
        ButtonText,
        LanguageSelect
    },
    setup(props) {

        const router = useRouter();

        const isCommunity = inject("isCommunity");

        const messageRef = ref();
        const formRef = ref();
        const componentSettings = reactive({
            use_label: false,
            use_placeholder: true
        });

        // Our component behaviour
        const componentData = reactive({
            type: 'login',
            form: []
        });
        const setType = (type) => {
            componentData.type = type;
            messageRef.value.resetMessages();
        };

        // Functions and helpers
        const handleForget = (formData) => {
            postData(`${apiHostname}/api/v1.0/user/forget_password`, formData).then(
                () => {
                    // And then go to login screen
                    setType("login");
                    messageRef.value.setMessage("Uw inloggegevens zijn verstuurd.", "message");
                },
                (json) => {
                    if (json.status === 404) {
                        messageRef.value.setMessage("Gebruiker is niet bekend.", "error");
                        return;
                    }
                    console.error("Error with validating the handle as Register form", json.status);
                    messageRef.value.setMessage("Er ging iets fout. Probeer het later nog eens.", "error");
                }
            );
        };

        const handleLogin = (formData) => {
            // // And now login
            fetchToken(formData).then(
                (result) => {
                    if (!result) {
                        messageRef.value.setMessage("Uw inloggegevens zijn mogelijk incorrect.", "error");
                    } else {
                        setTimeout(() => {
                            router.push(isCommunity.value ? "/portal" : "/");
                        }, 500);
                    }
                }
            );
        };

        const handleSubmit = (e) => {
            messageRef.value.resetMessages();
            form.handleSubmit().then(() => {
                const formData = new FormData(formRef.value);
                switch (componentData.type) {
                    case 'forget':
                        handleForget(formData);
                        break;
                    case 'login':
                        handleLogin(formData);
                        break;

                }
            });
        };

        const passwordLabel = computed(() => {
            let label = trans("Wachtwoord");
            if (componentData.type === "login") {
                label += " *";
            }
            return label;
        });

        const form = useFormValidation();
        watch(
            () => componentData.type,
            (type) => {
                componentData.form = getAuthenticationForm(type);
            },
            {
                immediate: true
            }
        );


        return {
            form,

            messageRef,
            trans,
            formRef,
            componentData,
            componentSettings,
            setType,

            passwordLabel,

            handleSubmit
        }
    },
});
</script>

<style>
</style>