<template>
    <template v-if="this.tooltip_information">
        <template v-if="!this.inline && !this.show_tooltip_inline">
            <button ref="tooltipRef" type="button" class="tooltipButton" data-bs-toggle="tooltip" data-bs-placement="right" :title="this.tooltip_information" data-bs-html="true">
                <Icon name="info" />
            </button>
        </template>
        <div class="note" v-else-if="this.inline && this.show_tooltip_inline">
            {{ this.tooltip_information }}
        </div>
    </template>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
// Our form helpers
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

import Icon from "components/icon.vue";

// Our helpers
import { mergeDeep } from "helpers/_objects";

import { Tooltip } from "bootstrap";

export default defineComponent({
    name: "tooltip-element",
    components: {
        Icon
    },
    props: mergeDeep(
        propDefaults,
        {
            inline: { // What instance is used, multi purpose component. One for inline and one for tooltip
                type: Boolean,
                required: false,
                default: false
            }
        }
    ),
    setup(props) {

        const tooltipRef = ref();
        onMounted(() => {
            if (!tooltipRef.value) return;
            if (props.tooltip_information && !props.show_tooltip_inline) {
                const tooltip = new Tooltip(tooltipRef.value, {
                    boundary: document.body
                })
            }
        });

        return {
            tooltipRef
        };
    }
});
</script>

<style>
</style>