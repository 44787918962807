<template>
    <template v-if="componentData.workflowcard">
        {{ componentData.title }}
    </template>
</template>

<script>
import {defineComponent, computed, inject, reactive, watch, onMounted} from "vue";

import {workflowlayout, resolvePropertyType, resolveTitle } from "store/workflow";

import { item, fetchItem } from "store/portal";

export default defineComponent({
    name: "CardTitle",
    props: {
        id: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: false,
            default: null
        },
        workflowboard_id: {
            type: String,
            required: false,
            default: ""
        },
        properties: {
            type: Object,
            required: false,
            default: {}
        }
    },
    setup(props) {

        const componentData = reactive({
            workflowcard: {},
            title: ""
        });
        componentData.workflowboard_id = computed(() => props.workflowboard_id || `${componentData.workflowcard?.extra?.workflowboard?.id}`);

        const setTitle = () => {
            componentData.title = resolveTitle(componentData.workflowcard, componentData.workflowboard_id, props.properties);
        };

        watch(
            () => props.properties,
            () => {
                setTitle();
            },
            {
                deep: true
            }
        );

        const onInit = () => {
            componentData.workflowcard = {};
            if (props.data) {
                // Situation where card is just passed
                componentData.workflowcard = props.data;
            } else {
                if (!props.id) return;
                fetchItem("workflowcard", props.id);
                componentData.workflowcard = computed(() => item("workflowcard", props.id));
            }
        };
        onMounted(onInit);
        watch(
            () => props.id,
            onInit
        );


        const tmpLayout = computed(() => workflowlayout(componentData.workflowboard_id, "title"));
        watch(
            () => [tmpLayout.value, componentData.workflowcard],
            (tmpLayout) => {
                if (!componentData.workflowcard) return;
                setTitle();
            },
            {
                immediate: true
            }
        );


        return {
            componentData,
            resolveTitle
        }
    }
});
</script>