<template>
    <div class="Pagination" v-if="pages > 1">
        <div class="middle">
            <button class="button inline first" @click="setFirstPage" :disabled="isInFirstPage()">
                <span class="title">{{ trans("Eerste") }}</span>
            </button>
            <button class="button inline previous" @click="setPreviousPage(page)" :disabled="isInFirstPage()">
                <span class="title">{{ trans("Vorige") }}</span>
            </button>
            <ul class="block-grid">
                <li v-for="page in pages" :class="{ hidden: isHidden(page) }" @click.prevent="setCurrentPage(page)">
                    <a :href="url(page)" :class="{ button: true, active: isPageActive(page) }">
                        <span class="title">{{ page }}</span>
                    </a>
                </li>
            </ul>
            <button class="button inline next" @click="setNextPage" :disabled="isInLastPage()">
                <span class="title">{{ trans("Volgende") }}</span>
            </button>
            <button class="button inline last" @click="setLastPage" :disabled="isInLastPage()">
                <span class="title">{{ trans("Laatste") }}</span>
            </button>
        </div>

        <div class="right" v-if="this.show_select">
            <div class="input-group pagination-select">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{ trans(this.show_select_label) }}:</span>
                </div>
                <select class="input form-control" v-if="show_select" v-model="settings.current" @change="setCurrentPage($event.target.value)">
                    <option v-for="page in pages" :value="page">{{ page }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>

import { getQuery, setQuery } from "helpers/_structure";
import { defineComponent, computed, reactive, watch, onMounted, onUnmounted, ref } from "vue";
import trans from "helpers/_translation";

import { getUrlWithQueryItemReplaced } from "helpers/_structure";


export default defineComponent({
    name: "Pagination",
    props: {
        id: {
            type: [Number, String],
            required: false
        },
        pagination_key: {
            type: String,
            required: false,
        },
        pagination_total: {
            type: [Number, String],
            required: false
        },
        pagination_limit: {
            type: [Number, String],
            required: false
        },
        maxVisibleButtons: {
            type: Number,
            required: false,
            default: 4
        },
        pagination_current: { // Almost never used only for loading specific page by default
            type: [Number, String],
            required: false,
            default: 1
        },
        show_select: {
            type: Boolean,
            required: false,
            default: true
        },
        show_select_label: {
            type: String,
            required: false,
            default: "Snel naar"
        }
    },
    setup(props, { emit } ) {

        const maxDifference = computed(() => props.maxVisibleButtons / 2);
        const total = computed(() => Number(props.pagination_total));
        const limit = computed(() => Number(props.pagination_limit));
        const pages = computed(() => Math.max(1, Math.ceil(total.value / limit.value)));

        const PAGE_KEY = props.pagination_key || `page_${props.id}`;

        const settings = reactive({
            current: Number(props.id ? (getQuery(PAGE_KEY) || 1) : props.pagination_current)
        });

        const isPageActive = (page) => {
            return computed(() => settings.current === Number(page)).value;
        };
        const isInFirstPage = () => {
            return computed(() => settings.current === 1).value;
        };
        const isInLastPage = () => {
            return computed(() => settings.current === pages.value).value;
        };

        const isHidden = (page) => {
            return computed(() => {
                const initialMinValue = settings.current - maxDifference.value;
                const initialMaxValue = settings.current + maxDifference.value;
                let minValue = initialMinValue;
                let maxValue = initialMaxValue;
                if (initialMinValue < 0) {
                    maxValue += (initialMinValue * -1);
                }
                if (initialMaxValue > pages.value) {
                    minValue -= ((initialMaxValue - 1) - pages.value);
                }
                return !(page >= minValue && page <= maxValue);
            }).value;
        };


        const setFirstPage = () => {
            settings.current = 1;
        };
        const setPreviousPage = () => {
            settings.current = Math.max(settings.current - 1, 1);
        };
        const setCurrentPage = (page) => {
            settings.current = Number(page);
        };
        const setLastPage = () => {
            settings.current = pages.value;
        };
        const setNextPage = () => {
            settings.current = Math.min(settings.current + 1, pages.value);
        };

        onMounted(() => {
            if (settings.current !== props.pagination_current) {
                // In that case it will be a URL defined page
                emit("updatePage", settings.current);
            }

            // Check for settings.current changes
            watch(
                () => settings.current,
                () => {
                    if (settings.current === 0) { settings.current = 1; }
                    emit("updatePage", settings.current);
                    if (props.id) {
                        setQuery(PAGE_KEY, settings.current);
                    }
                }
            );
            watch(
                () => props.pagination_current,
                () => {
                    settings.current = props.pagination_current;
                }
            );
            watch(
                () => total.value,
                (newTotal, oldTotal) => {
                    if (settings.current > pages.value) {
                        settings.current = pages.value;
                    } else if (!Number.isNaN(oldTotal)) {
                        settings.current = 1;
                    }
                }
            );
        });

        const initial_url = window.location.href;
        const url = (page) => {
            return getUrlWithQueryItemReplaced(PAGE_KEY, page, initial_url);
        };

        return {
            pages,
            total,
            limit,
            url,


            settings,

            isPageActive,
            isInFirstPage,
            isInLastPage,
            isHidden,

            setCurrentPage,
            setFirstPage,
            setPreviousPage,
            setNextPage,
            setLastPage,

            trans
        }
    },
});
</script>