<template>
    <div class="element PercentageField" :data-required="this.required" :class="error.error ? 'error' : ''">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <input
            class="input"
            type="number"
            min="0"
            max="100"
            step="1"
            pattern="[0-9]"
            :id="`field_${this.id}`"
            :name="this.name"
            :readonly="!this.editing || this.readonly"
            :placeholder="this.placeholder"
            :autocomplete="this.autocomplete"
            :maxlength="this.maxlength"
            v-model="input"
            @keypress="isNumber"
            @keyup="changeHandler($event) && validateInput($event)"
            @blur="validateInput"
        />
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<script>
import {ref, watch} from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    setup(props) {
        let input = ref("");
        const {validateTextField, error} = useFormValidation();
        const validateInput = () => {
            if (props.required) {
                validateTextField(props.label, input.value);
            }
        };

        const isNumber = (e) => {
            e = e ? e : window.event;
            const charCode = (e.which) ? e.which : e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                e.preventDefault();
            } else {
                return true;
            }
        };

        const changeHandler = (e) => {
            const target = e.target;
            if (Number(target.value) > 100) {
                target.value = 100
            }
        };

        watch(
            () => props.value,
            () => {
                input.value = props.value;
            },
            {
                immediate: true
            }
        );

        return {
            trans,
            input,
            error,
            validateInput,
            isNumber,
            changeHandler
        };
    },
}
</script>