<template>
    <div class="element ColorField" :data-required="this.required" :class="error.error ? 'error' : ''">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <input
            type="color"
            :id="`field_${this.id}`"
            :name="this.name"
            :readonly="!this.editing || this.readonly"
            :placeholder="this.placeholder"
            :autocomplete="this.autocomplete"
            :maxlength="this.maxlength"
            v-model="input"
            @keyup="validateInput"
            @blur="validateInput"
        />
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<script>
import {ref, watch} from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    setup(props) {
        let input = ref("");
        const {validateTextField, error} = useFormValidation();
        const validateInput = () => {
            if (props.required) {
                validateTextField(props.label, input.value);
            }
        };

        watch(
            () => props.value,
            () => {
                input.value = props.value ?? props.default_value;
            },
            {
                immediate: true
            }
        );

        return {input, error, validateInput, trans};
    },
}
</script>