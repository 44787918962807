<template>
    <template v-if="componentData.user">
        {{ componentData.user.data?.fullname }}
    </template>
</template>

<script>
import {defineComponent, onMounted, reactive, watch, ref, computed} from "vue";
import { useRouter } from "vue-router";

import { item, fetchItem } from "store/portal";

export default defineComponent({
    name: "Cardlink",
    props: {
        id: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: false
        }
    },
    components: {
    },
    setup(props) {
        const componentData = reactive({
            data: null
        });

        const onInit = () => {
            componentData.user = null;
            if (props.data) {
                // Situation where card is just passed
                componentData.user = props.data;
            } else {
                if (!props.id) return;
                fetchItem("user", props.id);
                componentData.user = computed(() => item("user", props.id));
            }
        };
        onMounted(onInit);
        watch(
            () => props.id,
            onInit
        );

        return {
            componentData
        }
    }
});
</script>