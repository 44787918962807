<template>
    <section class="Block History Overview">
        <template v-if="!componentData.data">
            <div class="loader"></div>
        </template>
        <ul class="block-grid one-up">
            <li v-for="item in componentData.data">
                <article class="Block History Item">
                    <div class="Content">
                        <time datetime="{{ item.version_date }}">{{ item.version_date }}</time>
                        <span class="content">
                            <strong v-if="item.subject">{{ tr(item.subject, item.extra_information ) }}</strong>
                            <template v-if="item.message">
                                <br>{{ item.message }}
                            </template>
                        </span>
                    </div>
                </article>
            </li>
        </ul>
    </section>
</template>

<script>
import {defineComponent, onMounted, onUnmounted, reactive, watch, inject} from "vue";

// Helper functions
import { getDataAsync } from "helpers/_api";
import { resolveData } from "helpers/_data";

import trans from "helpers/_translation";

// Config for API urls used in this vue template
import { config, apiHostname } from "config";

export default defineComponent({
    name: "history-overview",
    components: {},
    props: {
        item_id: {
            type: String,
            required: false
        },
        module_object: {
            type: String,
            required: false
        }
    },
    setup(props) {
        const viewData = inject("viewData");
        const componentData = reactive({
            data: null
        });

        const tr = (translation, parameters) => {
            let result = trans(translation);
            parameters = JSON.parse(parameters || "{}");
            for (const [key, value] of Object.entries(parameters)) {
                result = result.replace(`#${key}#`, value);
            }
            return result;
        };

        let lastDate = null;
        const getData = (newest = false) => {
            // console.log(props.module_object, props.item_id);
            const filters = [
                [
                    "FieldFilter",
                    {
                        "column": "module_object",
                        "value": props.module_object
                    }
                ],
                [
                    "FieldFilter",
                    {
                        "column": "item_id",
                        "value": props.item_id
                    }
                ]
            ];
            // Fetch json data
            if (!newest) {
                componentData.data = null;
            } else if (lastDate) {
                filters.push([
                    "FieldFilter",
                    {
                        "column": "version_date",
                        "operator": ">",
                        "value": lastDate
                    }
                ]);
            }
            getDataAsync(`${apiHostname}/${config.value.historylog.url}`, filters).then(result => {
                if (componentData.data === null) {
                    componentData.data = [];
                }
                componentData.data = [...resolveData(result),...componentData.data];
                lastDate = result._meta.timestamp;
            });
        };

        let historyWatcher;
        onMounted(() => {
            setTimeout(() => {
                watch(
                    () => [ props.module_object, props.item_id ],
                    () => {
                        if (!props.module_object || !props.item_id) return;
                        getData(false);
                    },
                    {
                        immediate: true
                    }
                );

                historyWatcher = watch(
                    () => viewData.ticker,
                    () => {
                        if (componentData.data === null) return;
                        getData(true)
                    }
                )
            }, 2500);
        });
        onUnmounted(() => {
            historyWatcher && historyWatcher();
        });

        return {
            componentData,
            tr,
            trans
        };
    },
});
</script>