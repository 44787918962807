<template>
    <nav>
        <button :disabled="this.readonly" type="button" @click="isUppyOpen = !isUppyOpen" :class="this.classname">
            <span class="title">{{ trans(this.text) }}</span>
        </button>
    </nav>
    <Teleport to="body">
    <dashboard-modal
        :uppy="uppy"
        :open="isUppyOpen"
        :props="{
            closeModalOnClickOutside: true,
            theme: 'light',
        }"
    />
    </Teleport>
</template>

<script>
import {defineComponent, ref } from "vue";

// Helper functions
import { getDataAsync } from "helpers/_api";
import { refreshToken , getBearer } from "services/JwtService";
import { apiHostname } from "config";

// External components
import { DashboardModal } from '@uppy/vue';
import trans from "helpers/_translation";

import Uppy from '@uppy/core';
import Dutch from '@uppy/locales/lib/nl_NL';
import English from '@uppy/locales/lib/en_US';
import Tus from '@uppy/tus';

// Components

// Config for API urls used in this vue template
import { config } from "config";

export default defineComponent({
    name: "file-upload",
    components: {
        DashboardModal
    },
    props: {
        item_id: {
            type: String,
            required: false
        },
        module_object: {
            type: String,
            required: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: true
        },

        classname: {
            type: String,
            required: false,
            default: "more outline"
        },
        text: {
            type: String,
            required: false,
            default: "Uploaden"
        },

        allowed_file_types: {
            type: String,
            required: false,
            default: "images/*,application/*"
        },
        max_file_size: {
            type: String,
            required: false,
            default: "1000000"
        },
        max_number_of_files: {
            type: String,
            required: false,
            default: "1"
        },

        // Meta data
        entity: {
            type: String,
            required: false,
            default: "Upload"
        },
        column_uploadfolder_id: {
            type: String,
            required: false,
            default: null
        }
    },
    emits: ["updateUpload"],
    setup(props, { emit }) {

        const isUppyOpen = ref(false);

        const handleClose = (e) => {
            console.log("e",e);
        };

        // Define resolved types for each key
        const types = {
            "restrictions": {
                "array": [
                    "allowed_file_types"
                ],
                "number": [
                    "max_file_size",
                    "max_number_of_files"
                ]
            },
            "meta": {
                "number": [
                    "column_uploadfolder_id"
                ],
                "string": [
                    "entity",
                    "module_object",
                    "item_id"
                ]
            }
        };
        const resolveType = (key, target) => {
            return Object.keys(types[target]).filter(type => { return types[target][type].includes(key)})?.[0];
        };
        const resolveParams = (target) => {
            Object.keys(props).forEach(key => {
                let val;
                switch (resolveType(key, target)) {
                    case 'number':
                        val = Number(props[key]);
                        break;
                    case 'array':
                        val = props[key].split(",");
                        break;
                    case 'string':
                        val = props[key];
                        break;
                }
                val && (data[target][key] = val);
            });
        };

        const data = {
            "restrictions": {},
            "meta": {}
        };
        // Store the data
        Object.keys(data).forEach(resolveParams);

        let timeout = false;
        const hasSuccess = ref(false);
        const languageAttr = document.querySelector('html')?.getAttribute('lang');
        const langMap = { "en": English,  "nl": Dutch }

        const uppy = new Uppy({
            id: "VueUppy",
            locale: (languageAttr && langMap[languageAttr]) ? langMap[languageAttr] : Dutch,
            autoProceed: true,
            allowMultipleUploadBatches: false,
            restrictions: data.restrictions,
            meta: data.meta,
            onBeforeFileAdded: (currentFile, files) => {
                currentFile.meta.name = currentFile.name.replaceAll("&", "").replaceAll(",", "");
                return currentFile;
            }
        })
            .use(Tus, {
                endpoint: `${apiHostname}${config.value.upload.file_url}`,
                retryDelays: [0, 1000, 3000, 5000],
                chunkSize: 5000 * 1000,
                headers: getBearer()
            })
            .on('dashboard:modal-closed', () => {
                isUppyOpen.value = false;
                if (hasSuccess.value) {
                    // location.reload();
                }
            })
            .on('complete', tus_result => {
                console.log('successful files:', tus_result.successful);
                console.log('failed files:', tus_result.failed);
                const getPromises = [];
                tus_result.successful.forEach(upload => {
                    const tmpPromise = new Promise(resolve => {
                        getDataAsync(upload.uploadURL).then(upload_result => {
                            resolve(upload_result);
                        });
                    });
                    getPromises.push(tmpPromise);
                });
                Promise.all(getPromises).then((result) => {
                    emit('updateUpload', tus_result, result);
                });
            })
            .on('upload-error', (file, error, response) => {
                if (!response && error.message.includes("response code: 401")) {
                    uppy.pauseAll();
                    if (!timeout) {
                        timeout = true;
                        refreshToken().then(() => {
                            uppy.getPlugin('Tus').setOptions({
                                headers: getBearer()
                            });
                            uppy.retryUpload(file.id);
                            setTimeout(() => { timeout = false; }, 500);
                        });
                    }
                }
            });

        return {
            uppy,
            isUppyOpen,
            handleClose,
            trans
        }
    },
});
</script>

<style>

</style>