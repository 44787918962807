<template>
    <div class="json-table" v-if="isArrayOfObjects(data)">
        <table>
            <thead>
            <tr>
                <!-- Generate headers dynamically from object keys -->
                <th v-for="header in headers" :key="header">{{ header }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in data" :key="index">
                <!-- Render each value corresponding to the headers -->
                <td v-for="header in headers" :key="header">
                    <template v-if="isObject(item[header])">
                        <JsonTable :data="item[header]" />
                    </template>
                    <template v-else-if="Array.isArray(item[header])">
                        <JsonTable :data="arrayToObject(item[header])" />
                    </template>
                    <template v-else>
                        {{ item[header] }}
                    </template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div v-else-if="isObject(data)">
        <ul class="block-grid one-up json-list">
            <li v-for="(key, index) in Object.keys(data)">
                <div class="title">{{ key }}:</div>
                <JsonTable :data="data[key]" :path="[...path, index]" />
            </li>
        </ul>
    </div>
    <div v-else>
        {{ data }}
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs, computed } from 'vue';

export default defineComponent({
    name: 'JsonTable',
    props: {
        data: {
            type: [Object, Array],
            required: true,
        },
        path: {
            type: Array,
            required: true
        }
    },
    setup(props) {

        const headers = computed(() => {
            // Extract headers if data is an array of objects
            if (Array.isArray(props.data) && props.data.length && isObject(props.data[0])) {
                return Object.keys(props.data[0]);
            }
            return [];
        });

        const isObject = (value) => {
            return computed(() => {
                return (typeof value === 'object') && (value !== null) && !Array.isArray(value);
            }).value;
        };
        const arrayToObject = (array) => {
            // Convert arrays into objects for consistent rendering
            return array.reduce((acc, val, index) => {
                acc[index] = val;
                return acc;
            }, {});
        };
        const isArrayOfObjects = (array) => {
            return computed(() => {
                // Check if the array is an array of objects
                return Array.isArray(array) && (typeof value !== 'object') && array.every(item => isObject(item));
            }).value;
        };

        return {
            headers,
            isObject,
            arrayToObject,
            isArrayOfObjects
        }
    }
});
</script>

<style scoped>
.json-table {
    overflow: auto;
    background-color: #2e3440;
    color: #d8dee9;
    font-size:var(--form-label_fontsize, 0.875rem);
}

.json-table table {
    width: 100%;
    border-collapse: collapse;
}

.json-table th {
    white-space:nowrap;
}
.json-table th,
.json-table td {
    padding: 8px;
    border: 1px solid #444c56;
    text-align:left;
}

.json-table th {
    background-color: #3b4252;
    color: #eceff4;
    text-transform: capitalize;
}

td {
    vertical-align: top;
    word-break: break-all;
}
.json-list {
    font-size:var(--form-label_fontsize, 0.875rem);
    > li {
        .title {
            font-weight:bold;
            text-transform:capitalize;
        }
    }
}
</style>
