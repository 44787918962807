<template>
    <section class="Block Workflowphase Overview list" :class="{loading: viewData.loading}">
        <ul class="block-grid one-up">
            <li>
                <template v-if="!viewData.loading && !viewData.data.length">
                    {{ trans("Momenteel is er geen data beschikbaar.") }}
                </template>
                <article v-else class="Block Workflowphase Item list">
                    <div class="inner">
                        <workflowcard-item v-for="(item, index) in sort(viewData.data)"
                           :key="item.id"
                           :data="item"
                           :layout="list"
                           :workflowboard_id="this.workflowboard_id"
                           :workflowphase_id="this.workflowphase_id"
                           :first="index === 0"
                        />
                    </div>
                </article>
            </li>
        </ul>
    </section>
</template>

<script>
import {defineComponent, inject} from "vue";

import workflowcardItem from "view/dashboard/includes/workflowcard-item";

import { sort } from "store/sorting";

import trans from "helpers/_translation";

export default defineComponent({
    name: "workflowphase-list",
    components: {
        workflowcardItem
    },
    props: {
        workflowboard_id: {
            type: String,
            required: true
        },
        workflowphase_id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        // Our configuration and constants
        const viewData = inject("viewData");
        return {
            trans,
            viewData,
            sort
        };
    },
});

</script>
