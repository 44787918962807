
export const chainPromiseCalls = async function (asyncFunctions = [], respectiveParams = []) {
	for (let i = 0; i < asyncFunctions.length; i++) {
		const eachResult = await asyncFunctions[i](...respectiveParams[i]);
		// do what you want to do with each result
	}
	return;
};

export const chainPromiseCallsWithoutParameters = async function (asyncFunctions = [], respectiveParams = []) {
	for (let i = 0; i < asyncFunctions.length; i++) {
		const eachResult = await asyncFunctions[i]();
		// do what you want to do with each result
	}
	return;
};

