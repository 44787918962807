<template>
    <ul class="block-grid messages" v-if="componentData.messages.length">
        <li v-for="message in componentData.messages">
            <div class="alert" :class="message.type">
                <Icon :name="message.type" />
                <span class="title" v-html="trans(message.title)"></span>
            </div>
        </li>
    </ul>
</template>

<script>
import {defineComponent, reactive} from "vue";

import Icon from "components/icon.vue";

// Our helpers
import trans from "helpers/_translation";

export default defineComponent({
    name: "message-overview",
    props: {},
    components: {
        Icon
    },
    setup() {

        const componentData = reactive({
            messages: []
        });

        const resetMessages = () => {
            componentData.messages = [];
        };
        const setMessage = (title, type = "error") => {
            componentData.messages.push({
                title,
                type
            });
        };

        return {
            trans,
            componentData,

            resetMessages,
            setMessage
        }
    },
});
</script>

<style>
</style>