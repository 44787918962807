import { reactive, computed } from "vue";

const notificationStore = reactive({
	data: {}
});
export const notifications = computed(() => {
	return notificationStore.data;
});

let notificationIndex = 0;
export const clearNotifications = () => {
	notificationStore.data = {};
};
export const addNotification = (text, timeout = 4000) => {
	const currentIndex = notificationIndex;
	notificationStore.data[currentIndex] = {
		text: text,
		visible: true
	};
	setTimeout(() => {
		notificationStore.data[currentIndex].visible = false;
		setTimeout(() => {
			delete notificationStore.data[currentIndex];
		}, 1500);
	}, timeout);
	notificationIndex += 1;
};