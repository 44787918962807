<template>
    <div v-if="contact" class="profile useHand" @click.prevent="handleClick('Dashboard profile')">
        <div class="avatar">

            <img v-if="user_avatar()" :src="user_avatar()" alt="" />
            <Icon v-else name="profile" />
        </div>
        <div class="details">
            <span class="name">{{ value(contact, env("variables.contact.properties.fullname") ) }}</span>
            <span class="email">{{ value(contact, env("variables.contact.properties.email") ) }}</span>
        </div>
    </div>
</template>

<script>
import {defineComponent, inject } from "vue";

import Icon from "components/icon";
import { contact, value, user_avatar } from "store/portal";
import trans from "helpers/_translation";
import { env } from "config";

export default defineComponent({
    name: "profile",
    props: {
    },
    components: {
        Icon,
    },
    setup() {

        const handleClick = inject("handleClick");

        return {
            env,
            value,
            user_avatar,
            contact,

            trans,
            handleClick
        };
    },
});
</script>