<template>
    <popup-modal ref="popupRef" :class="classes" @cancelmodal="handleCancel">
        <div class="Heading">
            <h3>{{ trans(componentData.title ?? "Weet u het zeker?") }}</h3>
        </div>
        <div class="Content" v-html="trans(componentData.message)"></div>
        <slot name="after_message"></slot>
        <nav>
            <button class="more outline" @click="handleConfirm">
                <span class="title">{{ trans(componentData.okButton) }}</span>
            </button>
            <button class="more" @click="handleCancel">
                <span class="title">{{ trans(componentData.cancelButton) }}</span>
            </button>
        </nav>
    </popup-modal>
</template>

<script>
import {defineComponent, reactive, ref, computed } from "vue";

import PopupModal from "components/popupModal.vue";
import trans from "helpers/_translation";

export default defineComponent({
    name: "PopupConfirm",
    components: {
        PopupModal
    },
    props: {
    },
    setup(props) {
        const popupRef = ref();

        const classes = computed(() => {
            return {
                Confirm: true
            }
        });

        const componentDefaults = {
            title: 'Weet u het zeker?',
            message: "U heeft wijzigingen aangebracht in het huidige formulier. <br>Als u doorgaat, worden uw wijzigingen niet opgeslagen.",
            okButton: 'Doorgaan',
            cancelButton: 'Annuleer',
            switchId: '',
            switchTitle: ''
        };
        const componentData = reactive({
            ...componentDefaults
        });
        // Private variables
        const resolveData = reactive({
            resolvePromise: undefined,
            rejectPromise: undefined
        });

        const show = (opts = {}) => {
            Object.keys(componentData).forEach(key => {
                componentData[key] = opts[key] || componentDefaults[key];
            });
            // Once we set our config, we tell the popup modal to open
            popupRef.value.open();
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolveData.resolvePromise = resolve;
                resolveData.rejectPromise = reject;
            });
        };

        const handleConfirm = () => {
            popupRef.value.close();
            resolveData.resolvePromise(true);
        };

        const handleCancel = () => {
            popupRef.value.close();
            resolveData.resolvePromise(false);
        };

        return {
            popupRef,
            trans,
            componentData,

            show,
            handleConfirm,
            handleCancel,

            classes
        }
    }
});
</script>